<div *ngIf="loadingItem" class="pl-pane__container--horizontal">
  <svg class="pl-spinner">
    <use xlink:href="#svg-spinner"></use>
  </svg>
</div>

<div *ngIf="!loadingItem" class="pl-pane__container--horizontal">
  <section class="pl-pane__nav">
    <div pl-grid="tight spaced align-middle">
      <div pl-grid-el pl-grid="shrink@phone">
        <a class="pl-button--minimal" [routerLink]="['../..']">
          <se-fe-icon seFeDataName="chevron_left"></se-fe-icon>
          {{ 'LIST_VIEW.title' | translate }}
        </a>
      </div>
    </div>
  </section>

  <section class="pl-pane__header">
    <div pl-grid="tight">
      <div pl-grid="grow@phone" pl-grid-el>
        <h1 class="pl-heading--xlarge pl-nowrap" [translate]="'DETAIL_VIEW.title'"></h1>
      </div>
      <div class="item__report--link">
        <a class="pl-link" [translate]="'DETAIL_VIEW.view_report'" [href]="getFinancialReportURL()"></a>
      </div>
    </div>
  </section>

  <section class="pl-pane__info">
    <div class="pl-centered-container--large">
      <div pl-grid="loose">
        <div pl-grid-el pl-grid="1/1@phone">
          <se-fe-card>
            <se-fe-card-header [seFeDataTitle]="item.name" [seFeDataSubtitle]="descriptionTitle"
              seFeDataHeavyBorder="true">
              <div seFeCardHeaderActions [hidden]="hideEditAction()">
                <se-fe-icon-button (click)="toggleItemModal()">
                  <se-fe-icon seFeDataName="pencil_fill" seFeDataSize="700" [seFeDataAriaLabel]="'Edit'"></se-fe-icon>
                </se-fe-icon-button>
              </div>
            </se-fe-card-header>
            <se-fe-card-content>
              <div *ngIf="item.variations.length <= 1" pl-grid="loose">
                <div pl-grid-el pl-grid="1/2@phone">
                  <span class="pl-field__label" [translate]="'DETAIL_VIEW.type'"></span>
                  <div class="pl-field__value">{{ zeroRateType() }}</div>
                </div>
                <div pl-grid-el pl-grid="1/2@phone">
                  <span class="pl-field__label" [translate]="'DETAIL_VIEW.sku'"></span>
                  <div class="pl-field__value">{{ defaultVariation.sku || '--' }}</div>
                </div>
                <div pl-grid-el pl-grid="1/2@phone">
                  <span class="pl-field__label" [translate]="'DETAIL_VIEW.price'"></span>
                  <div class="pl-field__value">
                    {{ defaultVariation.price | currency : defaultVariation.currency }}
                  </div>
                  <div *ngIf="showUpfrontAmountText" class="pl-help-text--below" [ngClass]="{
                      'line-break': showTaxCode && !!defaultVariation.tax_code
                    }" [innerHtml]="upfrontAmountSettingText"></div>
                </div>
                <div pl-grid-el pl-grid="1/2@phone">
                  <span class="pl-field__label" [translate]="'DETAIL_VIEW.upfront_amount'"></span>
                  <div class="pl-field__value">
                    {{ defaultVariation.upfront_amount | currency : defaultVariation.currency || '--' }}
                  </div>
                </div>

                <ng-container *ngFor="let priceSchedule of priceSchedules" pl-grid="1/1@phone" class="price_schedules">
                  <div pl-grid-el pl-grid="1/2@phone">
                    <span class="pl-field__label overflow-ellipsis">{{ priceSchedule.pricing_name }}</span>
                    <div class="pl-field__value">{{ priceSchedule.price_formatted || '--' }}</div>
                  </div>

                  <div pl-grid-el pl-grid="1/2@phone">
                    <span class="pl-field__label overflow-ellipsis">{{ priceSchedule.pricing_name }} Dates</span>
                    <div class="pl-field__value">
                      {{ priceSchedule.start_date | date : long }} - {{ priceSchedule.end_date | date : long }}
                    </div>
                  </div>
                </ng-container>

                <div *ngIf="showTaxCode && !!defaultVariation.tax_code" pl-grid-el pl-grid="1/3@phone">
                  <span class="pl-field__label" [translate]="'DETAIL_VIEW.tax_code'"></span>
                  <div>{{ defaultVariation.tax_code }}</div>
                </div>
              </div>
              <div *ngIf="item.variations.length >= 2" class="pl-data-table__container">
                <table class="sn-table se-store__overlay-positioning">
                  <thead>
                    <tr>
                      <th [translate]="'DETAIL_VIEW.variation'"></th>
                      <th class="sn-right-align" [translate]="'DETAIL_VIEW.price'"></th>
                      <th *ngIf="variationHasSku" [translate]="'DETAIL_VIEW.sku'"></th>
                      <th [translate]="'DETAIL_VIEW.sold'"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let variation of item.variations">
                      <td>
                        <a class="pl-link" [routerLink]="['../variation', variation.id, 'detail']">
                          {{ variation.name }}
                        </a>
                      </td>
                      <td class="sn-right-align">
                        {{ variation.price | currency : variation.currency }}
                      </td>
                      <td *ngIf="variationHasSku">
                        {{ variation.sku || '--' }}
                      </td>
                      <td>
                        {{ variationStats[item.id]?.sold_count ?? 0 }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </se-fe-card-content>
          </se-fe-card>
        </div>

        <div pl-grid-el pl-grid="1/1@phone">
          <se-fe-card>
            <se-fe-card-header [seFeDataTitle]="'DETAIL_VIEW.sales' | translate"
              [seFeDataSubtitle]="'DETAIL_VIEW.' + variationDescription | translate" seFeDataHeavyBorder="true">
              <div seFeCardHeaderActions>{{ salesSummary }}</div>
            </se-fe-card-header>
            <se-fe-card-content>
              <div *ngIf="loadingSaleItems" class="pl-pane se-store__spinner-container">
                <svg class="pl-spinner">
                  <use xlink:href="#svg-spinner"></use>
                </svg>
              </div>
              <se-fe-empty-state *ngIf="!loadingSaleItems && !saleItems.length"
                [seFeDataTitle]="'DETAIL_VIEW.nothing_to_report' | translate" [seFeDataSize]="500">
                {{ 'DETAIL_VIEW.not_sold' | translate }}
              </se-fe-empty-state>
              <div *ngIf="!loadingSaleItems && saleItems.length" class="pl-data-table__container">
                <table class="sn-table se-store__overlay-positioning">
                  <thead>
                    <tr>
                      <th [translate]="'DETAIL_VIEW.date'"></th>
                      <th [translate]="'DETAIL_VIEW.type'"></th>
                      <th [translate]="'DETAIL_VIEW.sale'"></th>
                      <th [translate]="'DETAIL_VIEW.member'"></th>
                      <th class="sn-right-align" [translate]="'DETAIL_VIEW.price'"></th>
                      <th [translate]="'DETAIL_VIEW.quantity'"></th>
                      <th class="sn-right-align" [translate]="'DETAIL_VIEW.item_total'"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let saleItem of saleItems">
                      <td>{{ saleItem.sale.created_at | date }}</td>
                      <td>{{ saleItem.sale.type }}</td>
                      <td>
                        <app-external-link *ngIf="saleItem.sale.sale_number"
                          [url]="this.externalLinkService.getSaleUrl(saleItem.sale.sale_number, saleItem.sale.type)">
                          {{ saleItem.sale.sale_number }}
                        </app-external-link>
                      </td>
                      <td>
                        <a *ngIf="saleItem.sale.persona_id" class="pl-link" target="_top"
                          (mouseenter)="getProfileUrl(saleItem)" (focus)="getProfileUrl(saleItem)"
                          [href]="saleItem.profileUrl">
                          {{ saleItem.metadata?.for?.title || saleItem.sale.user_full_name }}
                        </a>
                        <span *ngIf="!saleItem.sale.persona_id">
                          {{ saleItem.sale.user_full_name }}
                        </span>
                        <span *ngIf="!saleItem.sale.user_full_name" [translate]="'DETAIL_VIEW.anonymous_user'"></span>
                      </td>
                      <td class="sn-right-align">
                        {{ saleItem.price | currency : saleItem.currency }}
                      </td>
                      <td>{{ saleItem.quantity }}</td>
                      <td class="sn-right-align">
                        {{ saleItem.amount | currency : saleItem.currency }}
                      </td>
                    </tr>
                  </tbody>
                  <div [ngClass]="{ 'sn-table-overlay': showOverlay }"></div>
                </table>
              </div>

              <div *ngIf="!loadingSaleItems && saleItems.length">
                <hr class="pl-divider" />
                <app-pagination [resultType]="'DETAIL_VIEW.sales' | translate"></app-pagination>
              </div>
            </se-fe-card-content>
          </se-fe-card>
        </div>
      </div>
    </div>
  </section>
</div>
<div *ngIf="showItemModal && item" class="pl-overlay">
  <sale-item-element [organizationId]="currentOrgId" [earlyLatePricingEnabled]="earlyLatePricingEnabled"
    [originalItem]="item" [showInfoAlert]="hasSaleItems"
    [showSaleItemType]="item.variations[0].zero_rate_type !== null ? 'true' : 'false'"
    (closeSaleItemModal)="toggleItemModal()" (success)="onSuccess($event.detail)"></sale-item-element>
</div>
