<form [formGroup]="form">
  <se-fe-form-field [seFeDataConfig]="numberOfItemsConfig">
    <se-fe-form-field-text
      formControlName="minimumItemCount"
      seFeDataType="number"></se-fe-form-field-text>
  </se-fe-form-field>
  <input type="hidden" formControlName="minimumItemCount">
  <p>The customer must buy at least this amount of items to receive the discount</p>
  <div *ngIf="!hideItemSelection" class="element-container select-sale-item">
    <input type="hidden" formControlName="customerBuys">
    <app-sale-item-modal
      id="customer-buys-item-selector"
      class="select-sale-item__modal"
      [organizationId]="currentOrgId()"
      [selectedItemIds]="form.get('customerBuys')?.value"
      (selectionSaved)="updateCustomerBuys($event)"></app-sale-item-modal>

    @if (customerBuysControl.hasError('required') && customerBuysControl.touched) {
      <span class="select-sale-item__error">You must select at least one item.</span>
    }
  </div>
</form>
