<form [formGroup]="form">
  <div class="element-container select-sale-item">
    <app-sale-item-modal
      id="customer-gets-item-selector"
      class="select-sale-item__modal"
      [organizationId]="currentOrgId()"
      [selectedItemIds]="customerGetsControl.value"
      (selectionSaved)="updateCustomerGets($event)"></app-sale-item-modal>

    @if (customerGetsControl.hasError('required') && customerGetsControl.touched) {
      <span class="select-sale-item__error">You must select at least one item.</span>
    }
  </div>
  <br>
  <se-fe-form-field [seFeDataConfig]="discountWhatConfig">
    <se-fe-radios formControlName="discountWhat" class="discount-value-radios" [seFeDataOptions]="discountWhatOptions">
      <ng-template let-option let-checked="checked">
        @if (checked && option.value !== 'free') {
          <div class="radio-option-sidebar" *ngIf="checked"></div>
        }
        {{ option.label }}
        <div seFeHelp class="se-fe-form-field-option__description">
          {{ getDiscountWhatDescription(option.value) }}
        </div>
        @if (option.value !== 'free' && checked) {
          <div class="nested-input numeric-input">
            <se-fe-form-field [seFeDataConfig]="discountAmountConfig">
              <se-fe-form-field-text seFeDataType="number" formControlName="discountValue">
                <span *ngIf="option.value === 'amount'" seFeFormFieldTextBefore>$</span>
                <span *ngIf="option.value === 'percentage'" seFeFormFieldTextAfter>%</span>
              </se-fe-form-field-text>
            </se-fe-form-field>
          </div>
        }
      </ng-template>
    </se-fe-radios>
  </se-fe-form-field>
  <br>
  <se-fe-form-field [seFeDataConfig]="discountWhatScopeConfig">
    <se-fe-radios formControlName="discountWhatScope" class="discount-value-radios"
                  [seFeDataOptions]="discountWhatScopeOptions">
      <ng-template let-option let-checked="checked">
        @if (checked && option.value === 'item') {
          <div class="radio-option-sidebar"></div>
        }
        {{ option.label }}
        <div seFeHelp class="se-fe-form-field-option__description">
          {{ getDiscountWhatDescription(option.value) }}
        </div>
        @if (option.value === 'item' && checked) {
          <div class="nested-input numeric-input">
            <se-fe-form-field [seFeDataConfig]="discountAmountConfig">
              <se-fe-form-field-text seFeDataType="number" formControlName="howManyItems"></se-fe-form-field-text>
            </se-fe-form-field>
          </div>
        }
      </ng-template>
    </se-fe-radios>
  </se-fe-form-field>
  <!-- JMB: Feature is not implemented yet on the backend -->
  <!--<br>
  <se-fe-form-field [seFeDataConfig]="discountWhatLimitConfig">
    <se-fe-checkbox formControlName="limitNumberOfUses" [seFeDataOptions]="discountWhatLimitOptions"></se-fe-checkbox>
    <div seFeHelp class="se-fe-form-field-option__description limit-uses-help">
      Used to prevent customers from receiving too many items at a discounted value
    </div>
  </se-fe-form-field>
  @if (form.value['limitNumberOfUses']) {
    <div class="maximum-uses">
      <span>
        <div class="maximum-uses-sidebar"></div>
        <div class="nested-input numeric-input" style="margin-left: 26px">
          <se-fe-form-field [seFeDataConfig]="discountWhatLimitValueConfig">
            <se-fe-form-field-text seFeDataType="number" formControlName="maxUses"></se-fe-form-field-text>
          </se-fe-form-field>
        </div>
      </span>
    </div>
  }-->
</form>
