import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { SeFeApiService } from 'se-fe-api';
import { FiscalYear } from '../models/fiscal-year.model';
import { CURRENT_ORG_ID } from '../providers/current-org-id.provider';

@Injectable({
  providedIn: 'root',
})
export class FiscalYearService {
  private tenantType = 'organization';
  private endpoint = 'fiscal_years';

  constructor(@Inject(CURRENT_ORG_ID) protected currentOrgId: string, private api: SeFeApiService) {
    this.tenantType = this.tenantType.concat(`:${currentOrgId}`);
    this.endpoint = this.endpoint.concat(`/${this.tenantType}`);
  }

  public find(): Observable<FiscalYear> {
    return this.api
      .get(this.endpoint)
      .pipe(first())
      .pipe(map((data) => data.result as FiscalYear));
  }

  public fiscalYearStartDate(fiscalYear: FiscalYear): Date {
    const startDate = new Date(
      new Date().getFullYear(),
      this.getDatePart(fiscalYear.fiscal_start_date, 'month') - 1,
      this.getDatePart(fiscalYear.fiscal_start_date, 'day')
    );
    if (startDate > new Date()) {
      startDate.setFullYear(startDate.getFullYear() - 1);
    }

    return startDate;
  }

  public fiscalYearEndDate(fiscalYear: FiscalYear): Date {
    const endDate = this.fiscalYearStartDate(fiscalYear);
    endDate.setFullYear(endDate.getFullYear() + 1);
    endDate.setDate(endDate.getDate() - 1);
    return endDate;
  }

  private getDatePart(fiscalStartDate: string, datePart: string): number {
    if (!fiscalStartDate) {
      return 1;
    }

    const dateParts = fiscalStartDate.split('-');

    if (datePart === 'month') {
      return parseInt(dateParts[1], 10);
    }
    return parseInt(dateParts[2], 10);
  }
}
