import { PriceSchedule } from "./price-schedule.model";

export class ItemVariation {
  created_at: any;
  currency: string;
  full_name: string;
  id: number;
  item_id: number;
  name: string;
  price: string;
  price_cents: number;
  sku: string;
  sold_count?: number;
  tax_code?: string;
  tax_integration_attributes?: any;
  upfront_amount: string;
  upfront_amount_cents: number;
  uuid: string;
  zero_rate_type: number | string;
  active_price_schedule: PriceSchedule | undefined;
  price_variations?: PriceSchedule[];

  constructor(params: Partial<ItemVariation>) {
    this.id = params.id;
    this.created_at = params.created_at;
    this.currency = params.currency;
    this.full_name = params.full_name;
    this.name = params.name;
    this.price = params.price;
    this.price_cents = params.price_cents;
    this.sku = params.sku;
    this.sold_count = params.sold_count;
    this.tax_code = params.tax_code;
    this.upfront_amount = params.upfront_amount;
    this.upfront_amount_cents = params.upfront_amount_cents;
    this.uuid = params.uuid;
    this.zero_rate_type = params.zero_rate_type;
    this.active_price_schedule = params.active_price_schedule;
    this.price_variations = params.price_variations || [];
  }
}
