import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NOT_FOUND } from 'http-status-codes';

@Injectable()
export class AppErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  get router(): Router {
    return this.injector.get(Router);
  }

  get zone(): NgZone {
    return this.injector.get(NgZone);
  }

  private navigateTo(routeParams: any) {
    this.zone.run(async () => this.router.navigate(routeParams, { skipLocationChange: true }));
  }

  handleError(error: any) {
    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case NOT_FOUND:
          this.navigateTo(['not_found']);
          break;
        default:
          break;
      }
    }
    console.error(error);
  }
}
