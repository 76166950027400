import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LoadScriptsService {
  private renderer2: Renderer2;

  constructor(private rendererFactory2: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer2 = rendererFactory2.createRenderer(null, null);
  }

  public loadScripts(elementScript: string): void {
    // If the app and the element are running the same version of angular,
    // we don't need to reload the runtime or polyfills scripts. Reloading runtime
    // in this case will actually reload the entire app.
    const scripts = [elementScript, 'runtime', 'polyfills', 'styles', 'vendor']; // use if angular versions differ
    // const scripts = ['styles', 'vendor', 'saleItem']; // use if angular versions match
    scripts.forEach((src: string) => {
      this.injectScript(src);
    });
  }

  private injectScript(src: string): void {
    const id = 'se-financial-admin-' + src;
    const financialLibScript = this.document.getElementById(id);
    if (!financialLibScript) {
      const script = this.document.createElement('script');
      script.src = `${environment.financialAdminLibraryUrl}/${src}-es2015.js?v2`;
      script.type = 'text/javascript';
      script.id = id;
      script.async = false;
      this.renderer2.appendChild(this.document.body, script);
    }
  }
}
