import { SeMappableModel } from 'se-fe-service';

export class Discount extends SeMappableModel {
  additional_code_count: number;
  available_count: number;
  code_count: number;
  code_max_usage: string;
  created_at: string;
  currency_symbol: string;
  discount_scope: string;
  discount_style: string;
  discount_type: string;
  discount_value: string;
  discounted_items: string | number[];
  expires_at: string;
  id: number;
  max_eligible_items: number;
  max_eligible_type: string;
  max_use: string;
  max_use_count: number;
  maximum_discount_value: string;
  maximum_discount_value_cents: number;
  minimum_sale_total: string;
  minimum_sale_total_cents: number;
  name: string;
  organization_id: string;
  required_items: string | number[];
  required_items_quantity: number;
  requirement_type: string;
  sale_number: string;
  sale_type: string;
  single_use: boolean;
  starts_at: string;
  status: string;
  supplied_code: string;
  used_code_count: number;
  required_item_variation_ids?: number[];
  discounted_item_variation_ids?: number[];
  buyx_gety: boolean;
  applies_to_item_variation_ids?: number[];
}
