import { Component, EventEmitter, input, OnDestroy, OnInit, Output } from '@angular/core';
import { SeFeFormFieldConfig } from 'se-fe-form-field';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';


@Component({
  selector: 'app-customer-buys',
  templateUrl: './customer-buys.component.html',
  styleUrl: './customer-buys.component.scss',
})
export class CustomerBuysComponent implements OnInit, OnDestroy {
  @Output() minimumItemCountUpdated = new EventEmitter<number>();
  currentOrgId = input<string>();

  hideItemSelection = false;

  protected numberOfItemsConfig: SeFeFormFieldConfig = {
    label: 'Number of items',
    required: true,
    errorMessages: {
      required: 'Please enter a number of items.',
      pattern: 'Please enter a valid number.',
      min: 'Customer must buy at least 1 item.',
    },
  };

  onDestroy$ = new Subject<void>();

  readonly form = new FormGroup({
    customerBuys: new FormControl<number[]>([], [Validators.required]),
    minimumItemCount: new FormControl<number>(
      null,
      [
        Validators.required,
        Validators.min(1),
        Validators.pattern('^[0-9]*$'),
      ],
    ),
  });

  get customerBuysControl(): FormControl<number[]> {
    return this.form.get('customerBuys') as FormControl<number[]>;
  }

  get minimumItemCountControl(): FormControl<number> {
    return this.form.get('minimumItemCount') as FormControl<number>;
  }

  get value(): Partial<{ customerBuys: number[]; minimumItemCount: number }> {
    return this.form.value;
  }

  ngOnInit(): void {
    this.form.get('minimumItemCount').valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((value: number) => {
        this.hideItemSelection = value <= 0;
        if(this.hideItemSelection) {
          this.customerBuysControl.setValue([])
        }
        this.minimumItemCountUpdated.emit(value);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  protected updateCustomerBuys(products: number[]): void {
    this.customerBuysControl.setValue(products);
  }
}
