import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';

import { Discount } from '../models/discount.model';

import { TranslateService } from '@ngx-translate/core';
import { SeFeApiService } from 'se-fe-api';
import { SeFeServiceService } from 'se-fe-service';

@Injectable({ providedIn: 'root' })
export class DiscountService extends SeFeServiceService<Discount> {
  public endpoint = 'discount/code_definitions';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public Model: any = Discount;

  constructor(
    protected apiService: SeFeApiService,
    protected currencyPipe: CurrencyPipe,
    protected datePipe: DatePipe,
    protected decimalPipe: DecimalPipe,
    protected translateService: TranslateService
  ) {
    super(apiService);
  }

  public getDiscountType(discount: Discount): string {
    if (!discount.discount_style) {
      return null;
    }
    if (discount.discount_style === 'Code' || discount.discount_style === 'Individual') {
      return discount.single_use ? 'individual' : 'reusable';
    } else {
      return 'automatic';
    }
  }

  public getUsage(discount: Discount): string {
    return discount.code_max_usage ? `${discount.code_max_usage}` : 'Unlimited';
  }

  public getValue(discount: Discount): string {
    switch (discount.discount_type) {
      case 'amount':
        return this.currencyPipe.transform(this.formatCurrencyValue(discount.discount_value), discount.currency_symbol);
      case 'percentage':
        return `${Number(discount.discount_value)}%`;
    }
    return discount.discount_value;
  }

  public getValueMessage(discount: Discount): string {
    let description = '';
    const discountValue = this.getValue(discount);
    const hasDiscountMaxValue = !!discount.maximum_discount_value;
    if (!discountValue || !discount.discount_type) {
      return '';
    }

    if (hasDiscountMaxValue && discount.maximum_discount_value) {
      const maxDiscountValue = this.currencyPipe.transform(
        this.formatCurrencyValue(discount.maximum_discount_value),
        discount.currency_symbol
      );
      description = this.translateService.instant('DISCOUNTS.SUMMARY.VALUE.maximum', {
        value: discountValue,
        max: maxDiscountValue,
      });
    }

    if (!hasDiscountMaxValue) {
      description = this.translateService.instant('DISCOUNTS.SUMMARY.VALUE.default', { value: discountValue });
    }

    return description;
  }

  public getScopeMessage(discount: Discount, discountedItemCount: number, max_eligible_type: string): string {
    if (discount.discount_scope === 'Order') {
      return this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_scope.uneditable.order');
    }
    const countKey = discountedItemCount > 1 ? 'multi_selected' : 'one_selected';
    const messageKey = 'DISCOUNTS.DISCOUNT_FORM.discount_scope.uneditable.item.' + max_eligible_type + '.' + countKey;
    return this.translateService.instant(messageKey, {
      item_count: discountedItemCount,
      max_eligible: discount.max_eligible_items,
    });
  }

  public getRequirementsMessage(discount: Discount): string {
    switch (discount.requirement_type) {
      case 'PurchasePrice':
        return this.getMinValueMessage(discount);
      case 'Quality':
        return 'Selected items';
      default:
        return this.translateService.instant('DISCOUNTS.DISCOUNT_FORM.discount_requirements.uneditable.type.none');
    }
  }

  public getMinValueMessage(discount: Discount): string {
    const minValue = this.currencyPipe.transform(
      this.formatCurrencyValue(discount.minimum_sale_total),
      discount.currency_symbol
    );
    return this.translateService.instant('DISCOUNTS.SUMMARY.REQUIRE.min_price', { requiredPrice: minValue });
  }

  public getNumericValue(discount: Discount): string {
    switch (discount.discount_type) {
      case 'amount':
        return this.formatCurrencyValue(discount.discount_value);
      case 'percentage':
        return `${Number(discount.discount_value)}`;
    }
    return discount.discount_value;
  }

  public getActiveDates(discount: Discount): string {
    const start_date = this.datePipe.transform(discount.starts_at);
    const end_date = this.datePipe.transform(discount.expires_at);
    return discount.expires_at ? `${start_date} - ${end_date}` : start_date;
  }

  public getDiscountStyle(discount: Discount): string {
    const style = discount.discount_style;
    switch (style) {
      case 'Automatic':
        return style;
      case 'Individual':
        return `${style} Code`;
      case 'Code':
        return `Reusable Code`;
    }
  }

  public getDiscountStatus(discount: Discount): string {
    if (!discount || !discount.status) {
      return;
    }
    const status = discount.status;
    const isAvailable = discount.available_count !== 0;
    return status === 'active' && !isAvailable ? 'redeemed' : status;
  }

  public formatCurrencyValue(value: string): string {
    const decimalValue = this.decimalPipe.transform(value, '1.2-2');
    if (!decimalValue) {
      return '';
    }

    return decimalValue.includes(',') ? decimalValue.replace(/,/g, '') : decimalValue;
  }
}
