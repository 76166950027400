import { createAction, props } from '@ngrx/store';
import { Discount } from '../../models/discount.model';

export const loadDiscount = createAction('[Edit Discounts] Load Discount');

export const loadDiscountSuccess = createAction(
  '[Edit Discounts] Load Discount Success',
  props<{ discount: Discount }>()
);

export const loadDiscountFailure = createAction('[Edit Discounts] Load Discount Failure', props<{ error: Error }>());
