import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { SeAnalyticsService } from 'se-analytics';
import { SeFePaginationChange, SeFePaginationComponent, SeFePaginationOptions } from 'se-fe-pagination';
import { SeFeTableComponent, SeFeTableDataSource, SeFeTableSortDirective } from 'se-fe-table';
import { CURRENT_ORG_ID } from 'src/app/providers/current-org-id.provider';
import { AppExternalLinkService } from 'src/app/services/external-link.service';
import { CsvExportService } from '../../modules/csv-export/csv-export.service';
import { Discount } from '../models/discount.model';
import { DiscountCodeService } from '../services/discount-code.service';
import { DiscountService } from '../services/discount.service';
import { LaunchDarklyService } from '../services/launch-darkly.service';

@Component({
  selector: 'app-discount-detail',
  templateUrl: './discount-detail.component.html',
  styleUrls: ['./discount-detail.component.scss'],
})
export class DiscountDetailComponent implements OnInit, AfterViewInit, OnDestroy {
  public discountData: Discount;
  public discountCodeData = [];
  public discountPerformance: string[] = [];
  public headerSubtitles: string[];
  public isReusableCode = false;
  public pageLoading = true;
  public discountDataLoading = true;
  public discountID: number;
  public hideEmptyState = true;
  public useV2: boolean;
  public discountDataSource: SeFeTableDataSource;
  public displayedColumns = new BehaviorSubject<string[]>([
    // 'created_by',
    // 'sent_by',
    // 'sent_to',
    'code_as_entered',
    'redeemed_at',
    'user_full_name',
    'sale_number',
    'discount_total',
    // 'more'
  ]);
  public searchTerm = '';
  public currentPage = 1;
  public perPage = 25;
  public paginationOptions: SeFePaginationOptions = {
    name: 'paginationName',
    label: 'Discounts',
    currentPage: this.currentPage,
    total: this.discountCodeData.length,
  };

  constructor(
    @Inject(CURRENT_ORG_ID) public currentOrgId: number,
    private activatedRoute: ActivatedRoute,
    private csvExportService: CsvExportService,
    public discountService: DiscountService,
    public discountCodeService: DiscountCodeService,
    public externalLinkService: AppExternalLinkService,
    public translateService: TranslateService,
    public seAnalyticsService: SeAnalyticsService,
    public route: Router,
    public launchDarkly: LaunchDarklyService
  ) {
    this.useV2 = this.launchDarkly.enabled('edit-discounts');
  }

  @ViewChild(SeFeTableComponent)
  public discountTable!: SeFeTableComponent<ElementRef>;
  @ViewChild(SeFeTableSortDirective) public sorter!: SeFeTableSortDirective;
  @ViewChild(SeFePaginationComponent)
  public pagination!: SeFePaginationComponent;

  public async ngOnInit(): Promise<void> {
    this.discountID = this.activatedRoute.snapshot.params.discountId;
    this.discountDataSource = new SeFeTableDataSource();
    await this.loadDiscountData();
    this.discountData.max_use = this.discountData.max_use_count ? 'limited' : 'unlimited';
    this.headerSubtitles = this.getHeaderSubtitles(this.discountData);
    this.checkReusable();
    await this.loadDiscountCodeData();
    this.updateEmptyState();
    this.setPagination();
    this.seAnalyticsService.trackEvent('pageView', {
      depth1: 'HQ',
      depth2: 'Discounts',
      depth3: 'DiscountDetail',
    });
  }

  public ngAfterViewInit(): void {
    this.discountTable.dataSource = this.discountDataSource;
    this.sorter.seFeSortChange.subscribe(() => {
      this.discountCodeData = this.sortData();
      this.setPagedData();
    });
  }

  public ngOnDestroy(): void {
    this.sorter.seFeSortChange.unsubscribe();
  }

  public async loadDiscountData(): Promise<void> {
    this.discountData = await this.discountService.find(this.discountID).catch(err => {
      if (err.status === 404) {
        this.route.navigate(['/not-found'], { skipLocationChange: true });
      } else {
        this.route.navigate(['/error'], { skipLocationChange: true });
      }
      throw err;
    }).finally(() => {
      this.hideEmptyState = !this.discountData;
    });
  }

  public async loadDiscountCodeData(): Promise<void> {
    this.discountDataLoading = true;
    const indexParams = this.getCodeQueryParams();

    this.discountCodeData = await this.discountCodeService.findAll(indexParams).finally(() => {
      this.pageLoading = false;
      this.discountDataLoading = false;
    });
  }

  public exportAsCsv(): void {
    this.seAnalyticsService.trackEvent('seEvent', {
      depth1: 'HQ',
      depth2: 'Discounts',
      depth3: 'DiscountDetail',
      action: 'ExportRedemptions',
      event_type: 8,
    });
    const fileName = this.discountCodeService.getCsvExportFileName(this.discountData.name);
    this.csvExportService.saveAsCSV(fileName, this.discountCodeService.endpoint, this.getCodeQueryParams());
  }

  public editSummary() {
    this.seAnalyticsService.trackEvent('seEvent', {
      depth1: 'HQ',
      depth2: 'Discounts',
      depth3: 'DiscountDetail',
      action: 'EditSummary',
      event_type: 8,
    });
  }

  public async search(searchTerm: string): Promise<void> {
    this.searchTerm = searchTerm;
    this.currentPage = 1;
    this.discountDataLoading = true;
    await this.loadDiscountCodeData().finally(() => {
      this.reloadTable();
    });
  }

  public handlePagination(data: SeFePaginationChange) {
    this.currentPage = data.currentPage;
    this.perPage = data.limit;
    this.setPagedData();
  }

  public calculatePerformance(redeemed): void {
    const redeemedQuantity = redeemed.quantity;
    const redeemedValue = redeemed.value;

    this.discountPerformance.push(
      ...[
        `${redeemedQuantity} ${this.translateService.instant('DISCOUNTS.DETAIL_VIEW.PERFORMANCE.redeemed')}`,
        `${redeemedValue} ${this.translateService.instant('DISCOUNTS.DETAIL_VIEW.PERFORMANCE.discounted')}`,
      ]
    );
  }

  private getCodeQueryParams(): any {
    const singleUseSF = !this.isReusableCode ? ',discount_codes.code_as_entered' : '';
    return {
      organization_id: this.currentOrgId,
      'order[name]': 'asc',
      unpaginated: true,
      search_term: this.searchTerm,
      search_fields: `sales.sale_number,sales.user_full_name${singleUseSF}`,
      discount_code_definition_id: this.discountID,
    };
  }

  private checkReusable(): void {
    this.isReusableCode = !this.discountData.single_use;
    if (this.isReusableCode) {
      const displayColumnsCopy = this.displayedColumns.getValue();
      displayColumnsCopy.splice(this.displayedColumns.value.indexOf('code_as_entered'), 1);
      this.displayedColumns.next(displayColumnsCopy);
    }
  }

  private getHeaderSubtitles(discount: Discount): string[] {
    switch (discount.discount_style) {
      case 'Automatic':
        return [this.translateService.instant('DISCOUNTS.DETAIL_VIEW.SUBTITLE.automatic')];
      case 'Code':
        return [this.translateService.instant('DISCOUNTS.DETAIL_VIEW.SUBTITLE.code'), discount.supplied_code];
      case 'Individual':
        return [this.translateService.instant('DISCOUNTS.DETAIL_VIEW.SUBTITLE.individual')];
    }
  }

  private updateEmptyState() {
    this.hideEmptyState =
      this.pageLoading || this.discountDataLoading || !!this.discountCodeData.length || !!this.searchTerm;
  }

  private reloadTable(): void {
    this.discountCodeData = this.sortData();
    this.setPagination();
    this.updateEmptyState();
  }

  private sortData(): any[] {
    const data = this.discountCodeData;
    if (!this.sorter) {
      return data;
    }
    return [...data].sort((a, b) => {
      const factor = this.sorter.direction === 'asc' ? 1 : -1;
      let propA = a[this.sorter.active];
      let propB = b[this.sorter.active];
      let result;

      if (this.sorter.active === 'discount_total') {
        propA = Number(a.discount_total);
        propB = Number(b.discount_total);
      }

      if (typeof propA === 'string') {
        result = propA.toLowerCase().localeCompare(propB.toString().toLowerCase());
      } else {
        result = propA - propB;
      }
      return result * factor;
    });
  }

  private setPagedData() {
    this.discountDataSource.data = [
      ...this.discountCodeData.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage),
    ];
  }

  private setPagination() {
    this.setPagedData();
    this.paginationOptions.total = this.discountCodeData.length;
    this.pagination.limit = this.perPage;
    this.pagination.initializePagination();
  }
}
