import { BaseModel } from '../../models/base.model';
import { Sale } from './sale.model';

export class SaleItem extends BaseModel {
  amount: string;
  amount_cents: number;
  created_at: Date;
  currency: string;
  full_name: string;
  is_paid: boolean;
  item_uuid: string;
  item_variation_id: number;
  metadata: any;
  name: string;
  price: string;
  price_cents: number;
  profileUrl: string;
  quantity: number;
  sale: Sale;
}
