import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ItemVariation } from '../models/item-variation.model';
import { ApiResponse } from '../models/api-response.model';

@Injectable({
  providedIn: 'root'
})
export class ItemVariationService {
  constructor(
    private httpClient: HttpClient,
    private currencyPipe: CurrencyPipe) {}

  getItemVariations(params: object): Observable<ApiResponse<ItemVariation[]>> {
    return this.httpClient.get<ApiResponse<ItemVariation[]>>(this.getStatisticsUri(), this.getOptions(params));
  }

  getItemVariationsByID(params: object): Observable<ApiResponse<ItemVariation[]>> {
    return this.httpClient.get<ApiResponse<ItemVariation[]>>(this.getIndexUri(), this.getOptions(params));
  }

  upfrontAmountSettingText(variation: ItemVariation): string {
    let formattedUpfrontAmount = 'Full price';
    let paymentPlanText = 'not eligible for payment plans';
    const remainder = (variation.price_cents - variation.upfront_amount_cents) / 100;
    if (variation.upfront_amount_cents !== null && remainder > 0) {
      const formattedRemainer = this.formatCurrency(remainder, variation.currency);
      formattedUpfrontAmount = this.formatCurrency(variation.upfront_amount, variation.currency);
      paymentPlanText = `${formattedRemainer} due per payment plan`;
    }
    return `${formattedUpfrontAmount} due up front, \n${paymentPlanText}`;
  }

  protected getStatisticsUri(): string {
    return environment.apiUri + '/item_variations/statistics';
  }

  protected getIndexUri(): string {
    return environment.apiUri + '/item_variations';
  }

  private getOptions(params: object): object {
    return {
      params,
      withCredentials: true,
      headers: {
        Accept: 'application/json'
      }
    };
  }

  private formatCurrency(amount: string | number, currency: string): string {
    return this.currencyPipe.transform(amount, currency, 'symbol', '1.2-2');
  }

}
