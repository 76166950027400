import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api-response.model';
import { Item } from '../models/item.model';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  constructor(private httpClient: HttpClient) {}

  createItem(item: Item, params: object): Observable<ApiResponse<Item>> {
    return this.httpClient.post<ApiResponse<Item>>(this.getCreateUri(), item, this.getOptions(params));
  }

  editItem(item: Item, params: object): Observable<ApiResponse<Item>> {
    return this.httpClient.put<ApiResponse<Item>>(this.getRequestUri(item.id), item, this.getOptions(params));
  }

  getItem(itemId: string): Observable<ApiResponse<Item>> {
    return this.httpClient.get<ApiResponse<Item>>(this.getRequestUri(itemId), this.getOptions());
  }

  getItems(params: object): Observable<ApiResponse<Item[]>> {
    return this.httpClient.get<ApiResponse<Item[]>>(this.getCreateUri(), this.getOptions(params));
  }
  getItemsStatistics(params: object): Observable<ApiResponse<Item[]>> {
    return this.httpClient.get<ApiResponse<Item[]>>(this.getStatisticsUri(), this.getOptions(params));
  }

  protected getRequestUri(itemId: string): string {
    return environment.apiUri + '/items/' + itemId;
  }

  protected getCreateUri(): string {
    return environment.apiUri + '/items';
  }

  protected getStatisticsUri(): string {
    return environment.apiUri + '/items/statistics';
  }

  private getOptions(params?: object): object {
    return {
      params,
      withCredentials: true,
      headers: {
        Accept: 'application/json'
      }
    };
  }
}
