<se-fe-card>
  <se-fe-card-header [seFeDataTitle]="title || 'DISCOUNTS.DISCOUNT_FORM.'+stepID+'.title' | translate"></se-fe-card-header>
  <se-fe-card-content>
    <ng-content select="[EditableStepContent]"></ng-content>
  </se-fe-card-content>
  <se-fe-card-footer [seFeDataBorder]="stepID === currentStep">
    <se-fe-grid seFeGridOpts="snug right">
      <div [hidden]="stepID !== currentStep">
        <se-fe-button
          *ngIf="!isLastStep()"
          [seFeDataEmphasis]="'high'"
          (click)="nextStep('NextClick')">
          {{ 'DISCOUNTS.DISCOUNT_FORM.next' | translate }}
        </se-fe-button>
        <se-fe-button
          *ngIf="isLastStep()"
          [seFeDataEmphasis]="'high'"
          [seFeDataIsLoading]="submitting"
          (click)="nextStep('AllDoneClick')">
          {{ 'DISCOUNTS.DISCOUNT_FORM.submit' | translate }}
        </se-fe-button>
      </div>
    </se-fe-grid>
  </se-fe-card-footer>
</se-fe-card>
