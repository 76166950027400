import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PriceSchedule } from '../models/price-schedule.model';
import { ApiResponse } from '../models/api-response.model';

@Injectable({
  providedIn: 'root'
})

export class PriceScheduleService{
    constructor(private httpClient: HttpClient){}

    getPriceSchedules(params: object): Observable<ApiResponse<PriceSchedule[]>> {
        return this.httpClient.get<ApiResponse<PriceSchedule[]>>(this.getPriceScheduleUri(), this.getOptions(params));
      }

    protected getPriceScheduleUri(): string {
    return environment.apiUri + '/v3/commerce/clare/price_schedules/';
  }

  private getOptions(params: object): object {
    return {
      params,
      withCredentials: true,
      headers: {
        Accept: 'application/json'
      }
    };
  }

}