import { CurrencyPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SeFeApiService } from 'se-fe-api';
import { SeFeServiceService } from 'se-fe-service';

import { Discount } from '../models/discount.model';

@Injectable({ providedIn: 'root' })
export class DiscountCodeService extends SeFeServiceService<Discount> {
  public endpoint = 'discount/codes';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public Model: any = Discount;

  constructor(
    protected apiService: SeFeApiService,
    protected currencyPipe: CurrencyPipe,
    protected datePipe: DatePipe,
    public translateService: TranslateService
  ) {
    super(apiService);
  }

  public getRedeemedDate(redeemedDiscount: { redeemed_at: string | number | Date }) {
    return redeemedDiscount.redeemed_at
      ? this.datePipe.transform(redeemedDiscount.redeemed_at)
      : this.translateService.instant('DISCOUNTS.DETAIL_VIEW.PERFORMANCE.available');
  }

  public getRedeemedValue(redeemedDiscount: { discount_total: string | number; currency_symbol: string }) {
    return this.currencyPipe.transform(redeemedDiscount.discount_total, redeemedDiscount.currency_symbol);
  }

  public getCsvExportFileName(discount_name: string) {
    const tableName = this.translateService.instant('DISCOUNTS.DETAIL_VIEW.REDEMPTION.export_file_label');
    discount_name = discount_name
      .replace(/[^a-zA-Z0-9 ]/g, '') // strip out special characters
      .replace(' ', '-')
      .concat(tableName)
      .concat(`_${this.datePipe.transform(Date.now(), 'MM-dd-yyyy')}`);
    return discount_name;
  }
}
