import { Routes } from '@angular/router';
import { AddDiscountComponent } from './add-discount/add-discount.component';
import { DiscountDetailComponent } from './discount-detail/discount-detail.component';
import { DiscountListComponent } from './discount-list/discount-list.component';
import { EditDiscountComponent } from './edit-discount/edit-discount.component';
import { EditDiscountsV2Component } from './edit-discounts-v2/edit-discounts-v2.component';

export const routes: Routes = [
  { path: '', component: DiscountListComponent },
  { path: ':discountId/detail', component: DiscountDetailComponent },
  { path: 'new', component: AddDiscountComponent },
  { path: ':discountId/edit', component: EditDiscountComponent },
  { path: ':discountId/editv2', component: EditDiscountsV2Component },
];
