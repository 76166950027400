<div class="quick-filter-container">
  <div class="quick-filter-title">Quick Filters</div>
  <div class="quick-filter-set">
    <se-fe-filter-chips
      [seFeDataFilters]="dateFilters"
      [seFeDataMultiple]="false"
      [seFeDataRequired]="false"
      [seFeDataDisabled]="disableFilter"
      (seFeFilter)="filter('dateFilters', $event)">
    </se-fe-filter-chips>
  </div>

  <div class="quick-filter-set">
    <se-fe-filter-chips
      [seFeDataFilters]="statusFilters"
      [seFeDataDisabled]="disableFilter"
      (seFeFilter)="filter('statusFilters', $event)">
    </se-fe-filter-chips>
  </div>
</div>
