<div pl-grid="tight fixed align-right align-middle">
  <div pl-grid-el pl-grid="1/1@phone shrink@tablet" class="pl-text--center">
    <span class="pl-text"
      [translate]="'PAGINATION'"
      [translateParams]="{
        display_range: pagingService.displayRange,
        total: pagingService.pagedParameters.total,
        result_type: resultType}">
    </span>
  </div>
  <div *ngIf="pagingService.state.paginationVisible" pl-grid="1/1@phone shrink@tablet" class="pl-text--center">
    <div class="pl-field-group pagination-buttons__focus">
      <button class="pl-button pl-field-group__item" (click)="pagingService.previousPage()" [disabled]="!pagingService.state.buttonPreviousEnabled">
        <se-fe-icon seFeDataName="chevron_left"></se-fe-icon>
      </button>
      <button class="pl-button pl-field-group__item" (click)="pagingService.nextPage()" [disabled]="!pagingService.state.buttonNextEnabled">
        <se-fe-icon seFeDataName="chevron_right"></se-fe-icon>
      </button>
    </div>
  </div>
</div>
