import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Paged, PaginationState } from './paged';
import { PagedParameters } from './pagedParameters';

@Injectable({ providedIn: 'root' })
export class PagingService implements Paged, OnDestroy {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone
  ) {
    this.pagedParameters = PagingService.emptyPagedParameters();
    this.perPage = 25;
    this.subscribeToRouteParams();
    this.setState();
  }

  private queryParams: Params;
  state: PaginationState;
  pagedParameters: PagedParameters;
  perPage: number;
  displayRange: string;
  querySubscription: Subscription;

  static emptyPagedParameters(): PagedParameters {
    return {
      total: 0,
      total_pages: 1,
      first_page: true,
      last_page: false,
      current_page: 1,
      limit: 1,
      offset: 1,
    };
  }

  nextPage(): number {
    if (
      this.pagedParameters.current_page === this.pagedParameters.total_pages
    ) {
      return this.pagedParameters.current_page;
    }
    this.pagedParameters.current_page += 1;
    this.navigate();
    return this.pagedParameters.current_page;
  }

  collect(pagedParameters: PagedParameters): void {
    this.pagedParameters = pagedParameters;
    this.generateRange();
    this.setState();
  }

  previousPage(): number {
    if (this.pagedParameters.current_page === 1) {
      return 1;
    }
    this.pagedParameters.current_page -= 1;
    this.navigate();
    return this.pagedParameters.current_page;
  }

  toApiParams(mergedParams: Params): Params {
    if (this.pagedParameters) {
      mergedParams.page = this.pagedParameters.current_page;
    }
    mergedParams.per_page = this.perPage;
    return mergedParams;
  }

  generateRange(): void {
    const min = this.pagedParameters.offset + 1;
    let max: number;
    if (this.pagedParameters.last_page) {
      max = this.pagedParameters.total;
    } else {
      max = this.pagedParameters.offset + this.pagedParameters.limit;
    }
    this.displayRange = `${min}–${max}`;
  }

  subscribeToRouteParams(): void {
    this.queryParams = this.activatedRoute.snapshot.queryParamMap;
    this.querySubscription = this.activatedRoute.queryParamMap.subscribe(
      (params) => {
        this.pagedParameters.current_page = +params.get('page') || 1;
        this.perPage = +params.get('per_page') || 25;
      }
    );
  }

  unsubscribe(): void {
    this.querySubscription.unsubscribe();
  }

  private navigate(): void {
    this.ngZone.run(() => {
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: this.pagedParameters.current_page },
        queryParamsHandling: 'merge',
      });
    });
  }

  setState(): void {
    this.state = {
      paginationVisible: this.pagedParameters.total_pages > 1,
      buttonPreviousEnabled: this.pagedParameters.current_page > 1,
      buttonNextEnabled:
        this.pagedParameters.current_page < this.pagedParameters.total_pages,
    };
  }

  ngOnDestroy(): void {
    this.querySubscription.unsubscribe();
  }
}
