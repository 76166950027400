import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { SeFeChipFilter } from 'se-fe-chip';
import { SeAnalyticsService } from 'se-analytics';
import { FiscalYear } from '../../models/fiscal-year.model';
import { FiscalYearService } from '../../services/fiscal-year.service';

@Component({
  selector: 'app-quick-filters',
  templateUrl: './quick-filters.component.html',
  styleUrls: ['./quick-filters.component.scss']
})
export class QuickFiltersComponent implements OnInit {

  constructor(
    private fiscalYearService: FiscalYearService,
    private seAnalyticsService: SeAnalyticsService
  ) { }

  @Input() public disableFilter: boolean;
  @Output() public quickFilterChange = new EventEmitter<void>();

  public fiscalYear: FiscalYear;
  public selectedDateFilter: string;
  public selectedDateRange;
  public selectedStatuses: string[];

  public defaultFilter: QuickFilter = {
      date_value: null,
      date_range: this.dateRange(null),
      status: []
  };

  public dateFilters: SeFeChipFilter[] = [
    { label: 'Fiscal Year', value: 'fiscal-year', selected: false },
    { label: 'Current Year', value: 'current-year', selected: false },
    { label: 'Last Year', value: 'last-year', selected: false },
    { label: 'Current Month', value: 'current-month', selected: false },
    { label: 'Last Month', value: 'last-month', selected: false },
  ];

  public statusFilters: SeFeChipFilter[] = [
    { label: 'Active', value: 'active', selected: false },
    { label: 'Expired', value: 'expired', selected: false },
    { label: 'Scheduled', value: 'scheduled', selected: false },
  ];

  public async ngOnInit(): Promise<void> {
    const defaultFilter = this.defaultFilter;
    this.selectedDateFilter = defaultFilter.date_value;
    this.selectedDateRange = defaultFilter.date_range;
    this.selectedStatuses = defaultFilter.status;
    await this.loadFiscalYear();
  }

  public filter(filterType, $event) {
    switch (filterType) {
      case 'dateFilters':
        this.selectedDateFilter = $event[0] ? $event[0].value : null;
        this.selectedDateRange = this.dateRange(this.selectedDateFilter);
        break;
      case 'statusFilters':
        this.selectedStatuses = $event.map((status) => status.value);
        break;
    }
    this.applyFilterTracking(this.selectedDateFilter, this.selectedStatuses);
    this.quickFilterChange.emit();
  }

  private dateRange(date_value: string) {
    const today = DateTime.now();
    let startDate: DateTime;
    let endDate: DateTime;
    switch (date_value) {
      case 'fiscal-year':
        startDate = DateTime.fromJSDate(this.fiscalYearService.fiscalYearStartDate(this.fiscalYear));
        endDate = DateTime.fromJSDate(this.fiscalYearService.fiscalYearEndDate(this.fiscalYear));
        break;
      case 'current-year':
        startDate = today.startOf('year');
        endDate = today.endOf('year');
        break;
      case 'last-year':
        startDate = today.minus({ year: 1 }).startOf('year');
        endDate = today.minus({ year: 1 }).endOf('year');
        break;
      case 'last-month':
        startDate = today.minus({ month: 1 }).startOf('month');
        endDate = today.minus({ month: 1 }).endOf('month');
        break;
      case 'current-month':
        startDate = today.startOf('month');
        endDate = today.endOf('month');
        break;
    }

    return {
      start_date: startDate ? startDate.toISODate() : null,
      end_date: endDate ? endDate.toISODate() : null
    };
  }

  private async loadFiscalYear(): Promise<void> {
    this.fiscalYearService.find().subscribe(result => {
      this.fiscalYear = result;
    });
  }

  public setFiscalYear(fiscalYear?: FiscalYear) {
    this.fiscalYear = this.fiscalYear ? this.fiscalYear : fiscalYear;
    this.selectedDateFilter = 'fiscal-year';
    this.selectedDateRange = this.dateRange(this.selectedDateFilter);
    this.dateFilters.find(filter => filter.label === 'Fiscal Year').selected = true;
    this.quickFilterChange.emit();
  }

  public unset() {
    this.selectedDateFilter = null;
    this.selectedDateRange = null;
    this.dateFilters = this.dateFilters.map(filter => ({
        ...filter,
        selected: false
      }));


    this.quickFilterChange.emit();
  }

  public applyFilterTracking(dateFilter, statuses) {
    const filter: any = { count: 0 };
    let action: string;
    if (dateFilter) {
      filter.dateFilter = dateFilter;
      action = `QuickFilters.${filter.dateFilter}.${filter.count}`;
    }
    if (statuses.length > 0) {
      filter.statuses = statuses.join('.');
      filter.count = statuses.length;
      action = `QuickFilters.${filter.count}.${filter.statuses}`;
    }
    if (dateFilter && statuses.length > 0) {
      action = `QuickFilters.${filter.dateFilter}.${filter.count}.${filter.statuses}`;
    }
    this.seAnalyticsService.trackEvent('seEvent', {
      depth1: 'HQ',
      depth2: 'Discounts',
      action,
      event_type: 8
    });
  }
}

export interface QuickFilter {
  date_value: string;
  date_range;
  status: string[];
}
