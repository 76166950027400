import { Inject, Injectable } from '@angular/core';
import { CURRENT_ORG_ID } from 'src/app/providers/current-org-id.provider';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AppExternalLinkService {
  constructor(@Inject(CURRENT_ORG_ID) public currentOrgId: number) {}

  public getSaleUrl(saleNumber: string, saleType: string): string {
    let salePath = '';
    if (saleType === 'Invoice') {
      salePath = `/org/${this.currentOrgId}/billing/payments/bills/${saleNumber}/detail`;
    } else {
      salePath = `/org/${this.currentOrgId}/orders/${saleNumber}/detail`;
    }
    return environment.snFrontendBaseUrl + salePath;
  }

  public getMemberUrl(personaId: string): string {
    return `${environment.snFrontendBaseUrl}/org/${this.currentOrgId}/people/${personaId}/profile`;
  }
}
