import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { ApiResponse } from '../models/api-response.model';
import { StoreStatus } from '../models/store-status.model';

@Injectable({
  providedIn: 'root',
})
export class StoreStatusService {
  storeStatusSubject = new BehaviorSubject<StoreStatus>(new StoreStatus({}));
  $storeStatus = this.storeStatusSubject.asObservable();

  constructor(private http: HttpClient) {}

  setStoreStatus(organizationId: string): void {
    this.retrieveStoreStatus(organizationId).subscribe((response: ApiResponse<StoreStatus>) =>
      this.storeStatusSubject.next(response.result)
    );
  }

  public get isSet(): boolean {
    return typeof this.storeStatusSubject.value.exists === 'boolean';
  }

  protected retrieveStoreStatus(organizationId: string): Observable<ApiResponse<StoreStatus>> {
    return this.http.get<ApiResponse<StoreStatus>>(this.retrieveStoreStatusUri(organizationId), this.getOptions());
  }

  protected retrieveStoreStatusUri(organizationId: string): string {
    return environment.apiUri + encodeURI('/provisioning/stores/') + organizationId;
  }

  private getOptions(): object {
    return {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
      },
    };
  }
}
