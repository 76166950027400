import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api-response.model';
import { MembershipDefinition } from '../models/membership-definition.model';

@Injectable({
  providedIn: 'root'
})
export class MembershipDefinitionService {
  constructor(private httpClient: HttpClient) {}

  getMembershipDefinition(clareItemUuid: string, bossOrganizationId: string): Observable<ApiResponse<MembershipDefinition[]>> {
    const membershipUri = this.getRequestUri(clareItemUuid, bossOrganizationId);
    return this.httpClient.get<ApiResponse<MembershipDefinition[]>>(membershipUri, this.getOptions());
  }

  protected getRequestUri(clareItemUuid: string, bossOrganizationId: string): string {
    return environment.apiUri +
      '/membership_definitions?clare_item_uuid=' + clareItemUuid +
      '&boss_organization_id=' + bossOrganizationId;
  }

  getMembershipUrl(id: string, orgId: string): string {
    return environment.snFrontendBaseUrl + '/org/' + orgId + '/memberships/' + id + '/info';
  }

  private getOptions(params?: object): object {
    return {
      params,
      withCredentials: true,
      headers: {
        Accept: 'application/json'
      }
    };
  }
}
