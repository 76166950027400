import { createReducer, on } from '@ngrx/store';
import { Discount } from '../../models/discount.model';
import { loadDiscountSuccess } from './edit-discounts.actions';

export const EDIT_DISCOUNTS_FEATURE_KEY = 'editDiscounts';

export interface EditDiscountsState {
  discount: Discount | undefined;
}

export const initialEditDiscountState: EditDiscountsState = {
  discount: undefined,
};

export const editDiscountsReducer = createReducer(
  initialEditDiscountState,

  on(loadDiscountSuccess, (state, { discount }) => ({
    ...state,
    discount,
  }))
);

export const getDiscount = (state: EditDiscountsState) => state.discount;
