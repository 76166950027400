import { Routes } from '@angular/router';
import { ItemListComponent } from './item-list/item-list.component';
import { ItemDetailComponent } from './item-detail/item-detail.component';
import { ItemVariationDetailComponent } from './item-variation-detail/item-variation-detail.component';

export const routes: Routes = [
  {
    path: '',
    component: ItemListComponent
  },
  {
    path: ':saleItemId/detail',
    component: ItemDetailComponent
  },
  {
    path: ':saleItemId/variation/:variationId/detail',
    component: ItemVariationDetailComponent
  }
];
