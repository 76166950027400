import { BaseModel } from '../../models/base.model';
import { ItemVariation } from './item-variation.model';

export class Item extends BaseModel {
  description: string;
  default_variation_id: number;
  id: string;
  membership: boolean;
  name: string;
  uuid: string;
  variations: ItemVariation[];
  sku: string;

  getDefaultVariation() {
    return this.variations.find((variation) => variation.id === this.default_variation_id);
  }

  constructor(params: any) {
    super(params);
    this.variations = (params.variations || []).map((v: any) => new ItemVariation(v));
  }
}
