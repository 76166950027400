import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { SeAnalyticsModule } from 'se-analytics';
import { SeFeAlertModule } from 'se-fe-alert';
import { SeFeApiModule } from 'se-fe-api';
import { SeFeButtonModule } from 'se-fe-button';
import { SeFeCardModule } from 'se-fe-card';
import { SeFeChipModule, SeFeFilterChipsModule } from 'se-fe-chip';
import { SeFeConfirmModule } from 'se-fe-confirm';
import { SeFeDatepickerModule } from 'se-fe-datepicker';
import { SeFeEmptyStateModule } from 'se-fe-empty-state';
import { SeFeFormControlHelpModule } from 'se-fe-form-control-help';
import { SeFeFormFieldModule } from 'se-fe-form-field';
import { SeFeFormFieldOptionsModule } from 'se-fe-form-field-options';
import { SeFeFormFieldSuffixModule } from 'se-fe-form-field-suffix';
import { SeFeFormFieldTextModule } from 'se-fe-form-field-text';
import { SeFeFrameRouterModule, SeFeFrameRouterRedirectService } from 'se-fe-frame-router';
import { SeFeGridModule } from 'se-fe-grid';
import { SeFeHeaderModule } from 'se-fe-header';
import {
  SeFeIconModule,
  seFeIconChevronLeft,
  seFeIconChevronRight,
  seFeIconClipboardThin,
  seFeIconDiscountThin,
  seFeIconDotFill,
  seFeIconPencilFill,
  seFeIconPriceTagThin,
  seFeIconRemove,
  seFeIconSearch,
  seFeIconSearchThin,
  seFeIconSpotDiscount,
  seFeIconTrash,
  seFeIconWarning, seFeIconSpotClipboard,
} from 'se-fe-icon';
import { SeFeMenuModule } from 'se-fe-menu';
import { SeFeModalModule } from 'se-fe-modal';
import { SeFePageModule } from 'se-fe-page';
import { SeFePaginationModule } from 'se-fe-pagination';
import { SeFePositionModule } from 'se-fe-position';
import { SeFeProgressBarModule } from 'se-fe-progress-bar';
import { SeFeScrollModule } from 'se-fe-scroll';
import { SeFeServiceModule } from 'se-fe-service';
import { SeFeSpinnerModule } from 'se-fe-spinner';
import { SeFeTableModule } from 'se-fe-table';
import { SeFeToastModule } from 'se-fe-toast';
import { SeFeToolbarModule } from 'se-fe-toolbar';
import { SeFeTranslationsModule } from 'se-fe-translations';
import { environment } from '../environments/environment';
import { AppRedirectService } from './app-redirect.service';
import { AppComponent } from './app.component';
import { AddDiscountComponent } from './discounts/add-discount/add-discount.component';
import { CustomerBuysComponent } from './discounts/customer-buys/customer-buys.component';
import { CustomerGetsComponent } from './discounts/customer-gets/customer-gets.component';
import { DiscountDetailComponent } from './discounts/discount-detail/discount-detail.component';
import { DiscountFormComponent } from './discounts/discount-form/discount-form.component';
import { DiscountListComponent } from './discounts/discount-list/discount-list.component';
import { DiscountSummaryComponent } from './discounts/discount-summary/discount-summary.component';
import { EditDiscountComponent } from './discounts/edit-discount/edit-discount.component';
import { EditDiscountsV2Component } from './discounts/edit-discounts-v2/edit-discounts-v2.component';
import { EditDiscountsEffects } from './discounts/edit-discounts-v2/state/edit-discounts.effects';
import { editDiscountsReducer } from './discounts/edit-discounts-v2/state/edit-discounts.reducer';
import { EditableStepComponent } from './discounts/editable-step/editable-step.component';
import { QuickFiltersComponent } from './discounts/quick-filters/quick-filters.component';
import { SaleItemModalComponent } from './discounts/sale-item-modal/sale-item-modal.component';
import { ExternalLinkComponent } from './external-link/external-link.component';
import { CsvExportModule } from './modules/csv-export/csv-export.module';
import { NotFoundComponent } from './error/not-found/not-found.component';
import { baseHrefProvider } from './providers/base-href.provider';
import { routes } from './routes';
import { ItemDetailComponent } from './sale-items/item-detail/item-detail.component';
import { ItemListComponent } from './sale-items/item-list/item-list.component';
import { ItemVariationDetailComponent } from './sale-items/item-variation-detail/item-variation-detail.component';
import { PaginationComponent } from './sale-items/pagination/pagination.component';
import { AppErrorHandlerService } from './services/app-error-handler.service';
import { AppExternalLinkService } from './services/external-link.service';
import { LoadScriptsService } from './services/load-scripts.service';
import { SvgSpriteComponent } from './svg-sprite/svg-sprite.component';
import { UnknownErrorComponent } from './error/unknown-error/unknown-error.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    SeFeApiModule.forRoot({
      domainEnvironments: environment.domainEnvironments,
    }),
    ReactiveFormsModule,
    RouterModule.forRoot(routes),
    SeAnalyticsModule.forRoot({
      platProp: 'se_store_ui',
      platformSubSec: 'HQ',
      currentEnv: environment.name,
    }),
    SeFeAlertModule,
    SeFeButtonModule,
    SeFeCardModule,
    SeFeChipModule,
    SeFeConfirmModule,
    SeFeDatepickerModule,
    SeFeEmptyStateModule,
    SeFeFilterChipsModule,
    SeFeFormControlHelpModule,
    SeFeFormFieldModule,
    SeFeFormFieldOptionsModule,
    SeFeFormFieldTextModule,
    SeFeFrameRouterModule.forRoot({
      origin: environment.seFeFrameRouterOrigin,
      redirectUrl: environment.seFeFrameRouterRedirectUrl,
      overlaySelector: '.se-fe-modal--open, .pl-overlay, .pl-pane--full-screen-no-nav',
      overlayDelay: 300,
    }),
    SeFeGridModule,
    SeFeHeaderModule,
    SeFeIconModule,
    SeFeIconModule.include([
      {
        name: 'custom_sort_down',
        // eslint-disable-next-line max-len
        data: `<svg _ngcontent-kpx-c46="" xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 24 23" height="20" width="20" role="img" aria-hidden="true"><path _ngcontent-kpx-c46="" d="M12 14.95Q11.8 14.95 11.625 14.887Q11.45 14.825 11.3 14.675L6.675 10.05Q6.4 9.775 6.413 9.362Q6.425 8.95 6.7 8.675Q6.975 8.4 7.4 8.4Q7.825 8.4 8.1 8.675L12 12.575L15.925 8.65Q16.2 8.375 16.613 8.387Q17.025 8.4 17.3 8.675Q17.575 8.95 17.575 9.375Q17.575 9.8 17.3 10.075L12.7 14.675Q12.55 14.825 12.375 14.887Q12.2 14.95 12 14.95Z" class="se-fe-sort__caret"></path></svg>`,
      } as any,
      {
        name: 'custom_sort_up',
        // eslint-disable-next-line max-len
        data: `<svg _ngcontent-kpx-c46="" xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 24 23" height="20" width="20" role="img" aria-hidden="true"><path _ngcontent-kpx-c46="" d="M12 14.95Q11.8 14.95 11.625 14.887Q11.45 14.825 11.3 14.675L6.675 10.05Q6.4 9.775 6.413 9.362Q6.425 8.95 6.7 8.675Q6.975 8.4 7.4 8.4Q7.825 8.4 8.1 8.675L12 12.575L15.925 8.65Q16.2 8.375 16.613 8.387Q17.025 8.4 17.3 8.675Q17.575 8.95 17.575 9.375Q17.575 9.8 17.3 10.075L12.7 14.675Q12.55 14.825 12.375 14.887Q12.2 14.95 12 14.95Z" class="se-fe-sort__caret" style="transform:rotate(180deg);transform-origin:center"></path></svg>`,
      } as any,
      {
        name: 'custom_sort_up_down',
        // eslint-disable-next-line max-len
        data: `<svg _ngcontent-kpx-c46="" xmlns="http://www.w3.org/2000/svg" viewBox="0 -1 24 23" height="20" width="20" role="img" aria-hidden="true"><path _ngcontent-kpx-c46="" xmlns="http://www.w3.org/2000/svg" d="M8.225 8.325Q7.95 8.05 7.95 7.6Q7.95 7.15 8.225 6.875L11.3 3.8Q11.45 3.65 11.625 3.587Q11.8 3.525 12 3.525Q12.2 3.525 12.388 3.587Q12.575 3.65 12.7 3.8L15.8 6.9Q16.075 7.175 16.062 7.612Q16.05 8.05 15.775 8.325Q15.5 8.6 15.05 8.6Q14.6 8.6 14.325 8.325L12 6L9.65 8.35Q9.375 8.625 8.938 8.613Q8.5 8.6 8.225 8.325ZM12 20.575Q11.8 20.575 11.625 20.5Q11.45 20.425 11.3 20.3L8.225 17.225Q7.95 16.95 7.95 16.5Q7.95 16.05 8.225 15.775Q8.5 15.5 8.95 15.5Q9.4 15.5 9.675 15.775L12 18.1L14.35 15.75Q14.625 15.475 15.062 15.488Q15.5 15.5 15.775 15.775Q16.05 16.05 16.05 16.5Q16.05 16.95 15.775 17.225L12.7 20.3Q12.575 20.425 12.388 20.5Q12.2 20.575 12 20.575Z"></path></svg>`,
      } as any,
      seFeIconChevronLeft,
      seFeIconChevronRight,
      seFeIconClipboardThin,
      seFeIconDiscountThin,
      seFeIconDotFill,
      seFeIconPriceTagThin,
      seFeIconPencilFill,
      seFeIconRemove,
      seFeIconSearch,
      seFeIconSearchThin,
      seFeIconSpotDiscount,
      seFeIconTrash,
      seFeIconWarning,
      seFeIconSpotClipboard,
    ]),
    SeFeMenuModule,
    SeFeModalModule,
    SeFePageModule,
    SeFePaginationModule,
    SeFePositionModule,
    SeFeProgressBarModule,
    SeFeScrollModule,
    SeFeServiceModule.forRoot({
      domainEnvironments: environment.domainEnvironments,
    }),
    SeFeSpinnerModule,
    SeFeTableModule,
    SeFeToastModule,
    SeFeToolbarModule,
    SeFeTranslationsModule.forRoot(['en']),
    SeFeFormFieldSuffixModule,
    TranslateModule.forRoot(),
    StoreModule.forRoot({
      editDiscounts: editDiscountsReducer,
      router: routerReducer,
    }),
    EffectsModule.forRoot([EditDiscountsEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25 }),
    StoreRouterConnectingModule.forRoot(),
  ],
  exports: [RouterModule, TranslateModule],
  providers: [
    AppExternalLinkService,
    baseHrefProvider,
    CsvExportModule,
    CurrencyPipe,
    DatePipe,
    DecimalPipe,
    LoadScriptsService,
    { provide: ErrorHandler, useClass: AppErrorHandlerService },
    { provide: SeFeFrameRouterRedirectService, useClass: AppRedirectService },
  ],
  declarations: [
    AddDiscountComponent,
    AppComponent,
    CustomerBuysComponent,
    CustomerGetsComponent,
    DiscountDetailComponent,
    DiscountFormComponent,
    DiscountListComponent,
    DiscountSummaryComponent,
    EditDiscountComponent,
    EditDiscountsV2Component,
    EditableStepComponent,
    ExternalLinkComponent,
    ItemDetailComponent,
    ItemListComponent,
    ItemVariationDetailComponent,
    NotFoundComponent,
    PaginationComponent,
    QuickFiltersComponent,
    SaleItemModalComponent,
    SvgSpriteComponent,
    UnknownErrorComponent,
  ],
  bootstrap: [AppComponent],
  // This is needed for custom elements that are not registered Angular components in our application.
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
