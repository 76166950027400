import { Component, OnInit, Input } from '@angular/core';
import { PagingService } from './paging.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})

export class PaginationComponent implements OnInit {
  @Input() resultType: string;

  constructor(public pagingService: PagingService) { }

  ngOnInit() { }

}
