import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SeAnalyticsService } from 'se-analytics';
import { Discount } from '../models/discount.model';

@Component({
  selector: 'app-editable-step',
  templateUrl: './editable-step.component.html',
  styleUrls: ['./editable-step.component.scss']
})
export class EditableStepComponent implements OnInit {
  @Input() public discountData: Discount;
  @Input() public formMode: string;
  @Input() public stepID: string;
  @Input() public currentStep: string;
  @Input() public steps: string[];
  @Input() public formGroup: UntypedFormGroup;
  @Input() public submitting: boolean;
  @Input() title: string | undefined;
  @Output() public validateAndStep = new EventEmitter<EditableStepComponent>();

  public discountType: string;

  constructor(private seAnalyticsService: SeAnalyticsService) { }

  ngOnInit(): void {
  }

  public addTracking(action) {
    if (this.formMode === 'edit') {
      this.seAnalyticsService.trackEvent('seEvent', {
        depth1: 'HQ',
        depth2: 'Discounts',
        depth3: 'EditDiscount',
        action,
        event_type: 8
      });
    } else {
      this.seAnalyticsService.trackEvent('seEvent', {
        depth1: 'HQ',
        depth2: 'Discounts',
        depth3: 'AddDiscount',
        action,
        event_type: 8
      });
    }
  }

  public nextStep(stepClick: string) {
    if (this.stepID === 'discount_type') {
      this.discountType = this.discountData.single_use ? 'Individual' : 'Reusable';
      const action = `${this.currentStep}.${this.discountType}.${stepClick}`;
      this.addTracking(action);
    } else if (this.stepID === 'max_redeemable') {
      if (this.formGroup.value.max_use === 'limited') {
        const action = `${this.currentStep}.MaxTimes.${stepClick}`;
        this.addTracking(action);
      } else {
        const action = `${this.currentStep}.Unlimited.${stepClick}`;
        this.addTracking(action);
      }
    } else {
      const action = `${this.currentStep}.${stepClick}`;
      this.addTracking(action);
    }
    this.validateAndStep.emit(this);
  }

  public isLastStep() {
    if (this.steps) {
      if (this.steps.indexOf(this.stepID) > 0) {
        return this.steps.indexOf(this.stepID) === this.steps.length - 1;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
