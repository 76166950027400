import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { selectSelectedDiscountId } from '../../../state/router.selectors';
import { DiscountService } from '../../services/discount.service';
import { loadDiscount, loadDiscountFailure, loadDiscountSuccess } from './edit-discounts.actions';

@Injectable()
export class EditDiscountsEffects {
  loadDiscount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadDiscount),
      withLatestFrom(this.store.select(selectSelectedDiscountId)),
      switchMap(([, discountId]) =>
        from(this.discountsApi.find(discountId)).pipe(
          map((discount) => loadDiscountSuccess({ discount })),
          catchError((error) => of(loadDiscountFailure({ error })))
        )
      )
    )
  );

  loadDiscountsFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadDiscountFailure),
        tap((action) => console.error(action.error))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store: Store, private discountsApi: DiscountService) {}
}
