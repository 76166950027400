import { Component } from '@angular/core';

@Component({
  template: `
    <div class="pl-pane">
      <se-fe-empty-state [seFeDataTitle]="title">
        <se-fe-icon seFeEmptyStateIcon seFeDataName="clipboard_thin" seFeDataSize="950"></se-fe-icon>
        {{ message }}
      </se-fe-empty-state>
    </div>
  `
})
export class UnknownErrorComponent {
  title = "Whoa, that's not in the playbook";
  message = 'Something went wrong while trying to load the page. Please try again.';
}
