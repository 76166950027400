import { Routes } from '@angular/router';
import { SeFeFrameRouterService } from 'se-fe-frame-router';
import { SeFeTranslationsLoader } from 'se-fe-translations';
import { routes as DiscountRoutes } from './discounts/routes';
import { LaunchDarklyService } from './discounts/services/launch-darkly.service';
// Page components
import { NotFoundComponent } from './error/not-found/not-found.component';
import { routes as ItemRoutes } from './sale-items/routes';
import { UnknownErrorComponent } from './error/unknown-error/unknown-error.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'items',
    pathMatch: 'full',
  },
  {
    path: 'items',
    data: {
      frameRouterTitle: 'Sale Items',
      pageDepth: 'HQ.SaleItems'
    },
    canActivate: [
      SeFeFrameRouterService,
      SeFeTranslationsLoader,
      LaunchDarklyService
    ],
    children: ItemRoutes
  },
  {
    path: 'discounts',
    data: {
      frameRouterTitle: 'Discounts',
      pageDepth: 'HQ.Discounts'
    },
    canActivate: [
      SeFeFrameRouterService,
      SeFeTranslationsLoader,
      LaunchDarklyService
    ],
    children: DiscountRoutes
  },
  {
    path: 'error',
    component: UnknownErrorComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      pageDepth: 'NotFound'
    }
  },
];
