<se-fe-grid seFeGridOpts="center">
  <se-fe-grid-cell seFeGridOpts="1/1@mobile 1/2@tablet 1/3@desktop">
    <se-fe-card class="summary-card">
      <se-fe-card-header [seFeDataTitle]="'DISCOUNTS.DISCOUNT_FORM.SUMMARY.title' | translate"></se-fe-card-header>
      <se-fe-card-content
        *ngIf="showEmptyState()"
        [innerHTML]="'DISCOUNTS.DISCOUNT_FORM.SUMMARY.empty_state' | translate"></se-fe-card-content>
      <app-discount-summary
        *ngIf="!showEmptyState()"
        [discountData]="discount"
        [showName]="true"
        [currentStep]="currentStep"
        [updateSummary]="updateSummary.asObservable()"></app-discount-summary>
      <se-fe-card-content>
        <div *ngIf="currentIndex > 0" class="progress-label">
          {{
            'DISCOUNTS.DISCOUNT_FORM.SUMMARY.complete'
              | translate : { percent: (currentIndex / steps.length | percent) }
          }}
        </div>
        <div *ngIf="currentIndex === 0" class="progress-label">
          {{ 'DISCOUNTS.DISCOUNT_FORM.SUMMARY.complete_zero' | translate }}
        </div>
        <se-fe-progress-bar [seFeDataCompleted]="currentIndex" [seFeDataTotal]="steps.length"></se-fe-progress-bar>
      </se-fe-card-content>
    </se-fe-card>
  </se-fe-grid-cell>

  <se-fe-grid-cell seFeGridOpts="1/1@mobile 1/2@tablet 2/3@desktop">
    <div id="submission_errors" [hidden]="!hasSubmissionErrors">
      <se-fe-alert [seFeDataType]="'error'" [seFeDataHeader]="'DISCOUNTS.DISCOUNT_FORM.ERROR.header' | translate">
        <se-fe-alert-subheader>
          {{ 'DISCOUNTS.DISCOUNT_FORM.ERROR.subheader' | translate }}
          <ul>
            <li *ngFor="let error of submissionErrors">{{ error }}</li>
          </ul>
        </se-fe-alert-subheader>
      </se-fe-alert>
    </div>
    <form [formGroup]="discountForm">
      <se-fe-grid seFeGridOpts="center">
        <!-- DISCOUNT TYPE-->
        <se-fe-grid-cell seFeGridOpts="1/1">
          <app-editable-step
            [discountData]="discount"
            [formMode]="formMode"
            [stepID]="'discount_type'"
            [currentStep]="currentStep"
            [formGroup]="discountTypeFormGroup"
            (validateAndStep)="validateAndStep($event)">
            <div EditableStepContent *ngIf="canEdit('discount_type')">
              <se-fe-form-field [seFeDataConfig]="discountFormService.discountTypeConfig">
                <se-fe-radios formControlName="type" [seFeDataOptions]="discountFormService.typeOptions">
                  <ng-template let-option>
                    {{ option.label }}
                    <se-fe-chip *ngIf="option.new" seFeDataText="New" seFeDataStyle="green-emphasis"></se-fe-chip>
                    <div seFeHelp class="se-fe-form-field-option__help">
                      {{ option.help }}
                    </div>
                  </ng-template>
                </se-fe-radios>
              </se-fe-form-field>
            </div>
            <div EditableStepContent *ngIf="!canEdit('discount_type')">
              {{ 'DISCOUNTS.DISCOUNT_FORM.discount_type.uneditable.' + discountTypeFormGroup.value.type | translate }}
            </div>
          </app-editable-step>
        </se-fe-grid-cell>

        <!-- DISCOUNT NAME-->
        <a id="discount_name"></a>
        <se-fe-grid-cell seFeGridOpts="1/1" *ngIf="showStep('discount_name')">
          <app-editable-step
            [discountData]="discount"
            [formMode]="formMode"
            [stepID]="'discount_name'"
            [currentStep]="currentStep"
            [formGroup]="discountNameFormGroup"
            [steps]="steps"
            (validateAndStep)="validateAndStep($event)">
            <div EditableStepContent *ngIf="canEdit('discount_name')">
              <se-fe-form-field [seFeDataConfig]="discountFormService.discountNameConfig">
                <se-fe-form-field-text
                  formControlName="name"
                  [seFeDataPlaceholder]="'DISCOUNTS.DISCOUNT_FORM.discount_name.placeholder' | translate">
                  <se-fe-icon
                    *ngIf="discountFormService.hasUniqueNameError(submissionErrors)"
                    seFeFormFieldTextAfter
                    seFeDataName="warning"
                    seFeDataSize="500"
                    seFeDataColor="red"></se-fe-icon>
                </se-fe-form-field-text>
                <se-fe-form-control-errors
                  [seFeDataMessages]="{
                    uniqueness: 'DISCOUNTS.DISCOUNT_FORM.discount_name.unique.ui_error' | translate
                  }"
                  [seFeDataErrors]="
                    discountFormService.hasUniqueNameError(submissionErrors) ? ['uniqueness'] : []
                  "></se-fe-form-control-errors>
                <div seFeHelp>
                  {{ 'DISCOUNTS.DISCOUNT_FORM.discount_name.help' | translate }}
                </div>
              </se-fe-form-field>
            </div>
            <div EditableStepContent *ngIf="!canEdit('discount_name')">
              {{ discount.name }}
            </div>
          </app-editable-step>
        </se-fe-grid-cell>

        <!-- DISCOUNT CODE (REUSABLE CODES ONLY)-->
        <a id="discount_code"></a>
        <se-fe-grid-cell seFeGridOpts="1/1" *ngIf="showStep('discount_code')">
          <app-editable-step
            [discountData]="discount"
            [formMode]="formMode"
            [stepID]="'discount_code'"
            [currentStep]="currentStep"
            [formGroup]="discountCodeFormGroup"
            [steps]="steps"
            (validateAndStep)="validateAndStep($event)">
            <div EditableStepContent *ngIf="canEdit('discount_code')">
              <se-fe-form-field [seFeDataConfig]="discountFormService.discountCodeConfig">
                <se-fe-form-field-text
                  formControlName="supplied_code"
                  [seFeDataPlaceholder]="
                    'DISCOUNTS.DISCOUNT_FORM.discount_code.placeholder' | translate
                  "></se-fe-form-field-text>
              </se-fe-form-field>
            </div>
            <div EditableStepContent *ngIf="!canEdit('discount_code')">
              {{ discount.supplied_code }}
            </div>
          </app-editable-step>
        </se-fe-grid-cell>

        <!-- DISCOUNT VALUE-->
        <a id="discount_value"></a>
        <se-fe-grid-cell seFeGridOpts="1/1" *ngIf="showStep('discount_value')">
          <app-editable-step
            [discountData]="discount"
            [formMode]="formMode"
            [stepID]="'discount_value'"
            [currentStep]="currentStep"
            [formGroup]="discountValueFormGroup"
            [steps]="steps"
            title="What are you discounting?"
            (validateAndStep)="validateAndStep($event)">
            <div EditableStepContent *ngIf="canEdit('discount_value')">
              <se-fe-form-field [seFeDataConfig]="discountFormService.valueTypeConfig">
                <se-fe-radios formControlName="value_type" class="discount-value-radios" [seFeDataOptions]="discountFormService.valueTypeOptions">
                  <ng-template let-option let-checked="checked">
                    <div class="radio-option-sidebar" *ngIf="checked && option.value !== 'buyXGetY'"></div>
                    {{ option.label }}
                    <div seFeHelp class="se-fe-form-field-option__description">
                      {{ getDiscountValueDescription(option.value) }}
                    </div>
                    <div *ngIf="option.value !== 'buyXGetY' && checked" class="nested-input numeric-input">
                      <se-fe-form-field [seFeDataConfig]="discountAmountConfig">
                        <se-fe-form-field-text seFeDataType="number" formControlName="value_amount">
                          <span *ngIf="option.value === 'amount'" seFeFormFieldTextBefore>$</span>
                          <span *ngIf="option.value === 'percent'" seFeFormFieldTextAfter>%</span>
                        </se-fe-form-field-text>
                      </se-fe-form-field>
                    </div>
                    <div *ngIf="option.value === 'percentage' && checked" class="nested-input">
                      <div class="numeric-input" [hidden]="!showMaxValue">
                        <se-fe-form-field [seFeDataConfig]="discountFormService.maxValueConfig">
                          <se-fe-form-field-text seFeDataType="number" formControlName="maximum_discount_value"></se-fe-form-field-text>
                          <div
                            seFeHelp
                            *ngIf="
                              discountValueFormGroup.value.value_amount > 0 &&
                              discountValueFormGroup.value.maximum_discount_value > 0
                            "
                            [translate]="'DISCOUNTS.DISCOUNT_FORM.discount_value.maximum_discount_value.currency'"
                            [translateParams]="{
                              percent: discountValueFormGroup.value.value_amount,
                              max_value: discountService.formatCurrencyValue(
                                discountValueFormGroup.value.maximum_discount_value
                              )
                            }" class="foo-bar"></div>
                        </se-fe-form-field>
                      </div>
                      <a
                        *ngIf="discountValueFormGroup.value.value_type === 'percentage'"
                        (click)="toggleMaxValue()"
                        [translate]="
                          showMaxValue
                            ? 'DISCOUNTS.DISCOUNT_FORM.discount_value.value_type.hide_max'
                            : 'DISCOUNTS.DISCOUNT_FORM.discount_value.value_type.show_max'
                        "></a>
                    </div>
                </ng-template>
                </se-fe-radios>
              </se-fe-form-field>
            </div>
            <div EditableStepContent *ngIf="!canEdit('discount_value')">
              {{ discountService.getValueMessage(discount) }}
            </div>
          </app-editable-step>
        </se-fe-grid-cell>

        <!-- BUY X GET Y -->
        <a id="customer_buys"></a>
        <se-fe-grid-cell seFeGridOpts="1/1" *ngIf="showStep('customer_buys')">
          <app-editable-step
            [discountData]="discount"
            [formMode]="formMode"
            [stepID]="'customer_buys'"
            [currentStep]="currentStep"
            [steps]="steps"
            title="Customer Buys"
            (validateAndStep)="validateAndStep($event)">
            <div EditableStepContent>
              <app-customer-buys
                [currentOrgId]="currentOrgId"
                (minimumItemCountUpdated)="minimumCountUpdated($event)"></app-customer-buys>
            </div>
          </app-editable-step>
        </se-fe-grid-cell>

        <!-- Customer Gets -->
        <a id="customer_gets"></a>
        <se-fe-grid-cell seFeGridOpts *ngIf="showStep('customer_gets')">
          <app-editable-step
            [discountData]="discount"
            [formMode]="formMode"
            [stepID]="'customer_gets'"
            [currentStep]="currentStep"
            [steps]="steps"
            title="Customer Gets"
            (validateAndStep)="validateAndStep($event)">
            <div EditableStepContent>
              <app-customer-gets [currentOrgId]="currentOrgId"></app-customer-gets>
            </div>
          </app-editable-step>
        </se-fe-grid-cell>

        <!-- DISCOUNT SCOPE-->
        <a id="discount_scope"></a>
        <se-fe-grid-cell seFeGridOpts="1/1" *ngIf="showStep('discount_scope')">
          <app-editable-step
            [discountData]="discount"
            [formMode]="formMode"
            [stepID]="'discount_scope'"
            [currentStep]="currentStep"
            [formGroup]="discountScopeFormGroup"
            [steps]="steps"
            (validateAndStep)="validateAndStep($event)">
            <div EditableStepContent *ngIf="canEdit('discount_scope')">
              <se-fe-form-field [seFeDataConfig]="discountFormService.discountScopeConfig">
                <se-fe-radios
                  formControlName="scope_type"
                  [seFeDataOptions]="discountFormService.discountScopeOptions"></se-fe-radios>
              </se-fe-form-field>
              <div class="subsection" [hidden]="!showDiscountedItems">
                <div>
                  <se-fe-form-field [seFeDataConfig]="discountFormService.discountedItemsConfig" class="hidden-input">
                    <se-fe-form-field-text formControlName="discounted_items"></se-fe-form-field-text>
                  </se-fe-form-field>
                  <div class="element-container">
                    <app-sale-item-modal
                      id="item-selector"
                      class="sale-item-modal"
                      [organizationId]="currentOrgId"
                      [selectedItemIds]="discount.discounted_items"
                      [minItemPrice]="minItemPrice"
                      (selectionSaved)="updateDiscountedItems($event)"></app-sale-item-modal>
                  </div>
                </div>
                <div>
                  <se-fe-form-field [seFeDataConfig]="discountFormService.maxEligibleConfig">
                    <se-fe-radios
                      formControlName="max_eligible_item_type"
                      [seFeDataOptions]="discountFormService.maxEligibleOptions">
                      <ng-template let-option let-checked="checked">
                        {{ option.label }}
                        <div *ngIf="option.value === 'set' && checked" class="nested-input numeric-input">
                          <se-fe-form-field [seFeDataConfig]="discountFormService.maxEligibleCountConfig">
                            <se-fe-form-field-text formControlName="max_eligible_item_count"></se-fe-form-field-text>
                            <div seFeHelp *ngIf="discountScopeFormGroup.value.max_eligible_item_count > 1">
                              <span
                                *ngIf="
                                  discountScopeFormGroup.value.discounted_items === null ||
                                  discountScopeFormGroup.value.discounted_items.length === 0
                                "
                                [translate]="
                                  'DISCOUNTS.DISCOUNT_FORM.discount_scope.max_eligible_item_count.help.null_items'
                                "
                                [translateParams]="{
                                  max_eligible: discountScopeFormGroup.value.max_eligible_item_count
                                }"></span>
                              <span
                                *ngIf="
                                  discountScopeFormGroup.value.discounted_items &&
                                  discountScopeFormGroup.value.discounted_items.split(',').length === 1
                                "
                                [translate]="
                                  'DISCOUNTS.DISCOUNT_FORM.discount_scope.max_eligible_item_count.help.single_item'
                                "
                                [translateParams]="{
                                  max_eligible: discountScopeFormGroup.value.max_eligible_item_count
                                }"></span>
                              <span
                                *ngIf="
                                  discountScopeFormGroup.value.discounted_items &&
                                  discountScopeFormGroup.value.discounted_items.split(',').length > 1
                                "
                                [translate]="
                                  'DISCOUNTS.DISCOUNT_FORM.discount_scope.max_eligible_item_count.help.multi_items'
                                "
                                [translateParams]="{
                                  max_eligible: discountScopeFormGroup.value.max_eligible_item_count,
                                  item_count: discountScopeFormGroup.value.discounted_items.split(',').length
                                }"></span>
                            </div>
                          </se-fe-form-field>
                        </div>
                      </ng-template>
                    </se-fe-radios>
                  </se-fe-form-field>
                </div>
              </div>
            </div>
            <div EditableStepContent *ngIf="!canEdit('discount_scope')">
              <div class="content-subsection">
                {{
                  discountService.getScopeMessage(
                    discount,
                    discountedItemCount,
                    discountScopeFormGroup.value.max_eligible_item_type
                  )
                }}
              </div>
              <div *ngIf="showDiscountedItems">
                <div class="sale-item-table-container">
                  <table
                    #discountedItemsTable
                    seFeTable
                    [title]="'DISCOUNTS.DISCOUNT_FORM.discount_scope.uneditable.sale_item_table.title' | translate">
                    <ng-container seFeColumnDef="name">
                      <th seFeHeaderCell *seFeHeaderCellDef class="name-col">
                        {{ 'DISCOUNTS.DISCOUNT_FORM.discount_scope.uneditable.sale_item_table.name' | translate }}
                      </th>
                      <td seFeCell *seFeCellDef="let item">{{ item.full_name }}</td>
                    </ng-container>
                    <ng-container seFeColumnDef="sku">
                      <th seFeHeaderCell *seFeHeaderCellDef class="sku-col">
                        {{ 'DISCOUNTS.DISCOUNT_FORM.discount_scope.uneditable.sale_item_table.sku' | translate }}
                      </th>
                      <td seFeCell *seFeCellDef="let item">{{ item.sku }}</td>
                    </ng-container>
                    <ng-container seFeColumnDef="price" class="price-col">
                      <th seFeHeaderCell *seFeHeaderCellDef>
                        {{ 'DISCOUNTS.DISCOUNT_FORM.discount_scope.uneditable.sale_item_table.price' | translate }}
                      </th>
                      <td seFeCell *seFeCellDef="let item">{{ item.price }}</td>
                    </ng-container>
                    <tr seFeHeaderRow *seFeHeaderRowDef="saleItemTableColumns.value"></tr>
                    <tr seFeRow *seFeRowDef="let item; columns: saleItemTableColumns.value"></tr>
                  </table>
                </div>
              </div>
            </div>
          </app-editable-step>
        </se-fe-grid-cell>

        <!-- DISCOUNT REQUIREMENTS-->
        <a id="discount_requirements"></a>
        <se-fe-grid-cell seFeGridOpts="1/1" *ngIf="showStep('discount_requirements')">
          <app-editable-step
            [discountData]="discount"
            [formMode]="formMode"
            [stepID]="'discount_requirements'"
            [currentStep]="currentStep"
            [formGroup]="discountRequirementsFormGroup"
            [steps]="steps"
            (validateAndStep)="validateAndStep($event)">
            <div EditableStepContent *ngIf="canEdit('discount_requirements')">
              <se-fe-form-field [seFeDataConfig]="discountFormService.requirementTypeConfig">
                <se-fe-radios
                  formControlName="requirement_type"
                  [seFeDataOptions]="discountFormService.requirementTypeOptions">
                  <ng-template let-option let-checked="checked">
                    {{ option.label }}
                    <div *ngIf="option.value === 'PurchasePrice' && checked" class="nested-input numeric-input">
                      <se-fe-form-field [seFeDataConfig]="discountFormService.minSaleTotalConfig">
                        <se-fe-form-field-text formControlName="minimum_sale_total"></se-fe-form-field-text>
                        <div
                          seFeHelp
                          *ngIf="discountRequirementsFormGroup.value.minimum_sale_total > 0"
                          [translate]="'DISCOUNTS.DISCOUNT_FORM.discount_requirements.minimum_sale_total.help'"
                          [translateParams]="{
                            min_total: discountService.formatCurrencyValue(
                              discountRequirementsFormGroup.value.minimum_sale_total
                            )
                          }"></div>
                      </se-fe-form-field>
                    </div>
                  </ng-template>
                </se-fe-radios>
              </se-fe-form-field>
              <div class="subsection" [hidden]="discountRequirementsFormGroup.value.requirement_type !== 'Quantity'">
                <div>
                  <se-fe-form-field [seFeDataConfig]="discountFormService.requiredItemsConfig" class="hidden-input">
                    <se-fe-form-field-text formControlName="required_items"></se-fe-form-field-text>
                  </se-fe-form-field>
                  <div class="element-container">
                    <sale-item-selector-element
                      id="item-selector"
                      [organizationId]="currentOrgId"
                      [selectedItemIds]="discount.required_items"
                      [modalHeader]="
                        'DISCOUNTS.DISCOUNT_FORM.discount_requirements.required_items.modal_header'
                          | translate : { discount_name: discount.name }
                      "
                      (selectionSaved)="updateRequiredItems($event.detail)"></sale-item-selector-element>
                  </div>
                </div>
                <div class="numeric-input">
                  <se-fe-form-field [seFeDataConfig]="discountFormService.requiredItemsQuantityConfig">
                    <se-fe-form-field-text formControlName="required_items_quantity"></se-fe-form-field-text>
                    <div seFeHelp *ngIf="discountRequirementsFormGroup.value.required_items_quantity > 0">
                      <span
                        *ngIf="
                          discountRequirementsFormGroup.value.required_items === null ||
                          discountRequirementsFormGroup.value.required_items.length === 0
                        "
                        [translate]="
                          'DISCOUNTS.DISCOUNT_FORM.discount_requirements.required_items_quantity.help.null_items'
                        "
                        [translateParams]="{
                          required_quantity: discountRequirementsFormGroup.value.required_items_quantity
                        }"></span>
                      <span
                        *ngIf="
                          discountRequirementsFormGroup.value.required_items &&
                          discountRequirementsFormGroup.value.required_items.split(',').length === 1
                        "
                        [translate]="
                          'DISCOUNTS.DISCOUNT_FORM.discount_requirements.required_items_quantity.help.single_item'
                        "
                        [translateParams]="{
                          required_quantity: discountRequirementsFormGroup.value.required_items_quantity
                        }"></span>
                      <span
                        *ngIf="
                          discountRequirementsFormGroup.value.required_items &&
                          discountRequirementsFormGroup.value.required_items.split(',').length > 1
                        "
                        [translate]="
                          'DISCOUNTS.DISCOUNT_FORM.discount_requirements.required_items_quantity.help.multi_items'
                        "
                        [translateParams]="{
                          required_quantity: discountRequirementsFormGroup.value.required_items_quantity,
                          item_count: discountRequirementsFormGroup.value.required_items.split(',').length
                        }"></span>
                    </div>
                  </se-fe-form-field>
                </div>
              </div>
            </div>
            <div EditableStepContent *ngIf="!canEdit('discount_requirements')">
              <div class="content-subsection">
                {{
                  'DISCOUNTS.DISCOUNT_FORM.discount_requirements.uneditable.type.' +
                    (discount.requirement_type | lowercase)
                    | translate
                      : {
                          minimum_amount: this.discountService.formatCurrencyValue(discount.minimum_sale_total),
                          required_quantity: discount.required_items_quantity,
                          item_count: requiredItemCount
                        }
                }}
              </div>
              <div *ngIf="discount.requirement_type === 'Quantity'">
                <div class="sale-item-table-container">
                  <table
                    #requiredItemsTable
                    seFeTable
                    [title]="'DISCOUNTS.DISCOUNT_FORM.discount_scope.uneditable.sale_item_table.title' | translate">
                    <ng-container seFeColumnDef="name">
                      <th seFeHeaderCell *seFeHeaderCellDef class="name-col">
                        {{ 'DISCOUNTS.DISCOUNT_FORM.discount_scope.uneditable.sale_item_table.name' | translate }}
                      </th>
                      <td seFeCell *seFeCellDef="let item">{{ item.full_name }}</td>
                    </ng-container>
                    <ng-container seFeColumnDef="sku">
                      <th seFeHeaderCell *seFeHeaderCellDef class="sku-col">
                        {{ 'DISCOUNTS.DISCOUNT_FORM.discount_scope.uneditable.sale_item_table.sku' | translate }}
                      </th>
                      <td seFeCell *seFeCellDef="let item">{{ item.sku }}</td>
                    </ng-container>
                    <ng-container seFeColumnDef="price" class="price-col">
                      <th seFeHeaderCell *seFeHeaderCellDef>
                        {{ 'DISCOUNTS.DISCOUNT_FORM.discount_scope.uneditable.sale_item_table.price' | translate }}
                      </th>
                      <td seFeCell *seFeCellDef="let item">{{ item.price }}</td>
                    </ng-container>
                    <tr seFeHeaderRow *seFeHeaderRowDef="saleItemTableColumns.value"></tr>
                    <tr seFeRow *seFeRowDef="let item; columns: saleItemTableColumns.value"></tr>
                  </table>
                </div>
              </div>
            </div>
          </app-editable-step>
        </se-fe-grid-cell>

        <!-- MAXIMUM REDEEMABLE (REUSABLE & AUTOMATIC CODES ONLY)-->
        <a id="max_redeemable"></a>
        <se-fe-grid-cell seFeGridOpts="1/1" *ngIf="showStep('max_redeemable')">
          <app-editable-step
            [discountData]="discount"
            [formMode]="formMode"
            [stepID]="'max_redeemable'"
            [currentStep]="currentStep"
            [formGroup]="maxRedeemableFormGroup"
            [steps]="steps"
            (validateAndStep)="validateAndStep($event)">
            <div EditableStepContent>
              <se-fe-form-field [seFeDataConfig]="discountFormService.maxUseConfig">
                <se-fe-radios formControlName="max_use" [seFeDataOptions]="discountFormService.maxUseOptions" class="discount-value-radios">
                  <ng-template let-option let-checked="checked">
                    @if (checked && option.value === 'limited') {
                      <div class="radio-option-sidebar"></div>
                    }
                    {{ option.label }}
                    <div *ngIf="option.value === 'limited' && checked">
                      <div
                        *ngIf="formMode === 'edit'"
                        class="nested-input se-fe-form-control-label"
                        [translate]="'DISCOUNTS.DISCOUNT_FORM.max_redeemable.max_use_count.description'"
                        [translateParams]="{
                          redeemed: this.discount.used_code_count,
                          remaining: maxRedeemableFormGroup.value.max_use_count - this.discount.used_code_count
                        }"></div>
                      <div class="nested-input numeric-input">
                        <se-fe-form-field [seFeDataConfig]="discountFormService.maxUseCountConfig">
                          <se-fe-form-field-text formControlName="max_use_count"></se-fe-form-field-text>
                          <div
                            seFeHelp
                            *ngIf="maxRedeemableFormGroup.value.max_use_count > 0"
                            [translate]="'DISCOUNTS.DISCOUNT_FORM.max_redeemable.max_use_count.help'"
                            [translateParams]="{ max_use: maxRedeemableFormGroup.value.max_use_count }"></div>
                        </se-fe-form-field>
                      </div>
                    </div>
                  </ng-template>
                </se-fe-radios>
              </se-fe-form-field>
              <div *ngIf="maxRedeemableFormGroup.value.max_use === 'limited'"></div>
            </div>
          </app-editable-step>
        </se-fe-grid-cell>

        <!-- DISCOUNT DATES-->
        <a id="discount_dates"></a>
        <se-fe-grid-cell seFeGridOpts="1/1" *ngIf="showStep('discount_dates')">
          <app-editable-step
            [discountData]="discount"
            [formMode]="formMode"
            [stepID]="'discount_dates'"
            [currentStep]="currentStep"
            [formGroup]="discountDatesFormGroup"
            [steps]="steps"
            [submitting]="formSubmitting"
            (validateAndStep)="validateAndStep($event)">
            <div EditableStepContent style="display: flex">
              <se-fe-datepicker
                formControlName="starts_at"
                [seFeDataDateRangeIcon]="true"
                [seFeDataLabel]="'DISCOUNTS.DISCOUNT_FORM.discount_dates.start_date.label' | translate"
                [seFeDataHelpText]="'DISCOUNTS.DISCOUNT_FORM.discount_dates.start_date.help' | translate"
                [seFeDataYearRange]="{ future: 5, past: 0 }"
                [seFeDataExcludeDates]="excludeDatesAfterEnd()"
                [seFeDataMessages]="
                  'DISCOUNTS.DISCOUNT_FORM.discount_dates.start_date.errors' | translate
                "></se-fe-datepicker>
              <se-fe-datepicker
                formControlName="expires_at"
                [seFeDataDateRangeIcon]="true"
                [seFeDataLabel]="'DISCOUNTS.DISCOUNT_FORM.discount_dates.end_date.label' | translate"
                [seFeDataHelpText]="'DISCOUNTS.DISCOUNT_FORM.discount_dates.end_date.help' | translate"
                [seFeDataYearRange]="{ future: 5, past: 0 }"
                [seFeDataExcludeDates]="excludeDatesBeforeStart()"
                [seFeDataMessages]="
                  'DISCOUNTS.DISCOUNT_FORM.discount_dates.end_date.errors' | translate
                "></se-fe-datepicker>
            </div>
          </app-editable-step>
        </se-fe-grid-cell>

        <!-- CODE COUNT (INDIVIDUAL CODES ONLY)-->
        <a id="code_count"></a>
        <se-fe-grid-cell seFeGridOpts="1/1" *ngIf="showStep('code_count')">
          <app-editable-step
            [discountData]="discount"
            [formMode]="formMode"
            [stepID]="'code_count'"
            [currentStep]="currentStep"
            [steps]="steps"
            [formGroup]="codeCountFormGroup"
            [submitting]="formSubmitting"
            (validateAndStep)="validateAndStep($event)">
            <div EditableStepContent *ngIf="formMode === 'create'">
              <div class="numeric-input">
                <se-fe-form-field [seFeDataConfig]="discountFormService.codeCountConfig">
                  <se-fe-form-field-text formControlName="code_count"></se-fe-form-field-text>
                  <div seFeHelp>
                    {{ 'DISCOUNTS.DISCOUNT_FORM.code_count.help' | translate }}
                  </div>
                </se-fe-form-field>
              </div>
            </div>
            <div EditableStepContent *ngIf="formMode === 'edit'">
              <div
                class="content-section"
                [translate]="'DISCOUNTS.DISCOUNT_FORM.additional_code_count.description'"
                [translateParams]="{
                  count: this.discount.code_count,
                  remaining: this.discount.code_count - this.discount.used_code_count
                }"></div>
              <div class="numeric-input">
                <se-fe-form-field [seFeDataConfig]="discountFormService.additionalCodeConfig">
                  <se-fe-form-field-text formControlName="additional_code_count"></se-fe-form-field-text>
                  <div seFeHelp>
                    {{ 'DISCOUNTS.DISCOUNT_FORM.additional_code_count.help' | translate }}
                  </div>
                </se-fe-form-field>
              </div>
            </div>
          </app-editable-step>
        </se-fe-grid-cell>
      </se-fe-grid>
    </form>
  </se-fe-grid-cell>
</se-fe-grid>
