import { Injectable } from '@angular/core';
import { SeFeFrameRouterRedirectService } from 'se-fe-frame-router';

@Injectable({ providedIn: 'root' })
export class AppRedirectService implements SeFeFrameRouterRedirectService {
  private service = new SeFeFrameRouterRedirectService();

  // Custom implementation to support `sale_items` and `discounts` routes in HQ
  public redirectUrl(currentUrl: string, redirectUrl: string): string {
    const url = this.service.redirectUrl(currentUrl, redirectUrl);
    const appPath = url.includes('APP_PATH/discounts') ? 'promotions' : 'sale_items';
    return url.replace('APP_PATH', appPath);
  }
}
