<div *ngIf="!itemLoaded" class="pl-pane__container--horizontal">
  <svg class="pl-spinner">
    <use xlink:href="#svg-spinner"></use>
  </svg>
</div>

<div *ngIf="itemLoaded" class="pl-pane__container--horizontal">
  <section class="pl-pane__nav">
    <div pl-grid="tight spaced align-middle">
      <div pl-grid-el pl-grid="shrink@phone">
        <a class="pl-button--minimal" [routerLink]="['../../../detail']">
          <se-fe-icon seFeDataName="chevron_left"></se-fe-icon>
          {{ item.name }}
        </a>
      </div>
    </div>
  </section>

  <section class="pl-pane__header">
    <div pl-grid="tight">
      <div pl-grid="grow@phone" pl-grid-el>
        <h1 class="pl-heading--xlarge pl-nowrap" [translate]="'VARIATION_DETAIL_VIEW.title'"></h1>
      </div>
    </div>
  </section>

  <section class="pl-pane__info">
    <div class="pl-centered-container--large">
      <div pl-grid="loose">
        <div pl-grid-el pl-grid="1/1@phone">
          <se-fe-card>
            <se-fe-card-header [seFeDataTitle]="variation.name" seFeDataHeavyBorder="true"></se-fe-card-header>
            <se-fe-card-content>
              <div pl-grid="loose">
                <div pl-grid-el pl-grid="1/3@phone">
                  <span class="pl-field__label" [translate]="'VARIATION_DETAIL_VIEW.sale_item'"></span>
                  <div>
                    <a [routerLink]="['../../../detail']" class="pl-link">{{ item.name }}</a>
                  </div>
                </div>
                <div pl-grid-el pl-grid="1/3@phone">
                  <span class="pl-field__label" [translate]="'VARIATION_DETAIL_VIEW.price'"></span>
                  <div>{{ variation.price | currency : variation.currency }}</div>
                  <div
                    *ngIf="showUpfrontAmountText"
                    class="pl-help-text--below line-break"
                    [innerHTML]="upfrontAmountSettingText"></div>
                </div>
                <div pl-grid-el pl-grid="1/3@phone">
                  <span class="pl-field__label" [translate]="'VARIATION_DETAIL_VIEW.sku'"></span>
                  <div>{{ variation.sku || '--' }}</div>
                </div>
              </div>
            </se-fe-card-content>
          </se-fe-card>
        </div>

        <div pl-grid-el pl-grid="1/1@phone">
          <se-fe-card>
            <se-fe-card-header [seFeDataTitle]="'VARIATION_DETAIL_VIEW.sales' | translate" seFeDataHeavyBorder="true">
              <div seFeCardHeaderActions>{{ salesSummary }}</div>
            </se-fe-card-header>
            <se-fe-card-content>
              <div *ngIf="!saleItemLoaded" class="pl-pane se-store__spinner-container">
                <svg class="pl-spinner">
                  <use xlink:href="#svg-spinner"></use>
                </svg>
              </div>
              <se-fe-empty-state
                *ngIf="saleItemLoaded && !saleItems.length"
                [seFeDataTitle]="'VARIATION_DETAIL_VIEW.nothing_to_report' | translate"
                [seFeDataSize]="500">
                {{ 'VARIATION_DETAIL_VIEW.not_sold' | translate }}
              </se-fe-empty-state>
              <div *ngIf="saleItemLoaded && saleItems.length" class="pl-data-table__container">
                <table class="sn-table se-store__overlay-positioning">
                  <thead>
                    <tr>
                      <th [translate]="'VARIATION_DETAIL_VIEW.date'"></th>
                      <th [translate]="'VARIATION_DETAIL_VIEW.type'"></th>
                      <th [translate]="'VARIATION_DETAIL_VIEW.sale'"></th>
                      <th [translate]="'VARIATION_DETAIL_VIEW.member'"></th>
                      <th class="sn-right-align" [translate]="'VARIATION_DETAIL_VIEW.price'"></th>
                      <th [translate]="'VARIATION_DETAIL_VIEW.quantity'"></th>
                      <th class="sn-right-align" [translate]="'VARIATION_DETAIL_VIEW.item_total'"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let saleItem of saleItems">
                      <td>{{ saleItem.sale.created_at | date }}</td>
                      <td>{{ saleItem.sale.type }}</td>
                      <td>
                        <a [href]="getSaleUrl(saleItem.sale)" class="pl-link" target="_top">
                          {{ saleItem.sale.sale_number }}
                        </a>
                      </td>
                      <td>
                        <span *ngIf="saleItem.sale.persona_id">
                          <a [href]="getMemberUrl(saleItem.sale.persona_id)" class="pl-link" target="_top">
                            {{ saleItem.sale.user_full_name }}
                          </a>
                        </span>
                        <span *ngIf="!saleItem.sale.persona_id">{{ saleItem.sale.user_full_name }}</span>
                        <span
                          *ngIf="!saleItem.sale.user_full_name"
                          [translate]="'VARIATION_DETAIL_VIEW.anonymous_user'"></span>
                      </td>
                      <td class="sn-right-align">{{ saleItem.price | currency : saleItem.currency }}</td>
                      <td>{{ saleItem.quantity }}</td>
                      <td class="sn-right-align">{{ saleItem.amount | currency : saleItem.currency }}</td>
                    </tr>
                  </tbody>
                  <div [ngClass]="{ 'sn-table-overlay': showOverlay }"></div>
                </table>
              </div>

              <div *ngIf="saleItemLoaded && saleItems.length">
                <hr class="pl-divider" />
                <app-pagination [resultType]="'VARIATION_DETAIL_VIEW.sales' | translate"></app-pagination>
              </div>
            </se-fe-card-content>
          </se-fe-card>
        </div>
      </div>
    </div>
  </section>
</div>
