import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subscription, firstValueFrom, Subject } from 'rxjs';
import { SeFeApiService } from 'se-fe-api';
import { SeFeModalComponent } from 'se-fe-modal';
import { SeFePaginationChange, SeFePaginationOptions } from 'se-fe-pagination';
import { SeFeTableComponent, SeFeTableDataSource } from 'se-fe-table';
import { SeFeToolbarComponent } from 'se-fe-toolbar';
import { ItemVariation } from '../../sale-items/models/item-variation.model';
import { ItemVariationService } from '../../sale-items/services/item-variation.service';

@Component({
  selector: 'app-sale-item-modal',
  templateUrl: './sale-item-modal.component.html',
  styleUrls: ['./sale-item-modal.component.scss'],
})
export class SaleItemModalComponent implements OnInit, OnDestroy {
  @ViewChild('eligibleItemModal') public eligibleItemModal: SeFeModalComponent;
  @ViewChild(SeFeTableComponent) public displayTable!: SeFeTableComponent<ElementRef>;
  @ViewChild('displayTable') set displayTableComponent(displayTable: SeFeTableComponent<ElementRef>) {
    if (!displayTable) {
      return;
    }
    this.displayTable = displayTable;
  }
  @ViewChild(SeFeTableComponent) public selectTable!: SeFeTableComponent<ElementRef>;
  @ViewChild('selectTable') set selectTableComponent(selectTable: SeFeTableComponent<ElementRef>) {
    if (!selectTable) {
      return;
    }
    this.selectTable = selectTable;
    this.selectTable.dataSource = this.selectTableDataSource;
  }
  @ViewChild(SeFeToolbarComponent) public toolbar: SeFeToolbarComponent;

  @Input() modalHeader: string;
  @Input() organizationId: string;
  @Input() selectedItemIds: string;
  @Input() minItemPrice?: number;

  @Output() public selectionSaved = new EventEmitter<any>();

  private formSubscription: Subscription;
  public amountSelected: number;
  public displayTableColumns = new BehaviorSubject<string[]>(['name', 'sku', 'price', 'remove']);
  displayTableDataSource$ = new BehaviorSubject<SeFeTableDataSource>(undefined);
  public isLoaded = false;
  public itemVariationList: ItemVariation[] = [];
  public itemVariations$: Observable<ItemVariation[]>;
  public paginationOptions: SeFePaginationOptions;
  public searchTerm = '';
  public selectAllOptions = [{ label: 'Select All', value: true, hiddenLabel: true }];
  public selectedItems: ItemVariation[] = [];
  public selectedItems$: Observable<number[]>;
  public selectedItemsCount$: Observable<number>;
  public selectedItemsLoaded = true;
  public selectForm: FormGroup;
  public selectTableColumns = new BehaviorSubject<string[]>(['checkbox', 'name', 'sku', 'price']);
  public selectTableDataSource: SeFeTableDataSource;
  public query$ = new BehaviorSubject('');
  public isEmptyForPrice = false

  constructor(
    private apiService: SeFeApiService,
    private itemVariationService: ItemVariationService,
    private fb: FormBuilder
  ) {}

  public async ngOnInit(): Promise<void> {
    this.amountSelected = 0;
    this.paginationOptions = { label: 'sale items', name: 'itemVariation', currentPage: 1, limit: 25, total: 0 };
    this.selectTableDataSource = new SeFeTableDataSource([]);

    this.createForm();

    await this.loadItems();
    await this.resetModal();
  }

  public createForm(): void {
    this.selectForm = this.fb.group({
      selectAll: [false],
    });

    this.selectForm.controls.selectAll.valueChanges.subscribe((isSelected: boolean) => {
      this.toggleAllSelections(isSelected);
    });

    this.subscribeToSelectFormChanges();
  }

  queryChange(query: string): void {
    this.query$.next(query);
  }

  private toggleSelection(formValue: { [id: string]: boolean }) {
    for (const id in formValue) {
      if (!id) {
        continue;
      }
      const itemIndex = this.selectedItemIndex(parseInt(id, 10));
      if (!!formValue[id] && itemIndex === -1) {
        const itemModel = this.itemVariationList.find((item) => item.id.toString() === id);
        if (itemModel) {
          this.selectedItems.push(itemModel);
        }
      }
      if (!formValue[id] && itemIndex !== -1) {
        this.selectedItems.splice(itemIndex, 1);
      }
    }
  }

  toggleAllSelections(isSelected: boolean): void {
    this.itemVariationList.forEach((item) => {
      this.selectForm.controls[item.id.toString()]?.setValue(isSelected, { emitEvent: false });
    });

    if (isSelected) {
      this.selectedItems = [...this.itemVariationList];
    } else {
      this.selectedItems = [];
    }

    this.updateDisplayTable();
  }

  public removeItem(itemId: number): void {
    const itemIndex = this.selectedItemIndex(itemId);
    if (itemIndex !== -1) {
      this.selectedItems.splice(itemIndex, 1);
      this.updateDisplayTable();
      this.updateForm();
      this.selectionSaved.emit(this.currentlySelected());
    }
  }

  public async saveSelection(): Promise<void> {
    this.eligibleItemModal.close();
    this.updateDisplayTable();
    this.selectionSaved.emit(this.currentlySelected());
    await this.resetModal();
  }

  public async resetSelection(): Promise<void> {
    this.eligibleItemModal.close();
    await this.resetModal();
  }

  public async handlePagination(paginationData: SeFePaginationChange): Promise<void> {
    this.paginationOptions.limit = paginationData.limit;
    this.paginationOptions.currentPage = paginationData.currentPage;
    await this.loadItems();
  }

  public async search(searchTerm: string): Promise<void> {
    this.searchTerm = searchTerm;
    this.paginationOptions.currentPage = 1;
    await this.loadItems();
  }

  public async resetModal(): Promise<void> {
    this.paginationOptions.currentPage = 1;
    this.paginationOptions.limit = 25;
    this.searchTerm = '';
    this.toolbar?.searchForm.setValue({ searchTerm: '' });
    if (this.selectedItemIds?.length) {
      await this.loadSelectedItems(this.selectedItemIds);
    }
    await this.loadItems();
  }

  public async loadItems(): Promise<void> {
    this.isLoaded = false;
    const params = {
      ...this.baseParams,
      ...this.paginationParams,
      ...this.searchParams,
      ...this.sortParams,
    };
    const results = await firstValueFrom(this.apiService.get<ItemVariation[]>('item_variations', { params })).finally(() => {
      this.isLoaded = true;
    });
    if(this.minItemPrice !== -1 && results.result.length === 0) {
      this.isEmptyForPrice = true
    }
    this.itemVariationList = results.result;
    this.selectTableDataSource.data = this.itemVariationList;
    this.setPaginationOptions(results.metadata.pagination);
    this.updateForm();
  }

  private async loadSelectedItems(selectedItemIds: string): Promise<void> {
    this.selectedItemsLoaded = false;
    const params = {
      id_list: this.selectedItemIds,
      unpaginated: true,
      ...this.baseParams,
    };
    this.itemVariationService.getItemVariations(params).subscribe((results) => {
      this.selectedItems = results.result;
      this.updateDisplayTable();
      this.selectedItemsLoaded = true;
    });
  }

  private updateDisplayTable(): void {
    this.sortSelected();
    this.displayTableDataSource$.next(new SeFeTableDataSource(this.selectedItems));
    this.selectedItemIds = this.currentlySelected().join(',');
    this.amountSelected = this.selectedItems.length;
  }

  updateForm(): void {
    this.selectForm = this.fb.group({
      selectAll: [false]
    });
    this.formSubscription?.unsubscribe();
    this.itemVariationList.forEach((item) => {
      const isChecked = this.currentlySelected().includes(item.id);
      this.selectForm.addControl(String(item.id), new FormControl(isChecked));
    });
    this.formSubscription = this.selectForm.valueChanges.subscribe((formValue) => this.toggleSelection(formValue));
    this.selectForm.controls.selectAll.valueChanges.subscribe((isSelected: boolean) => {
      this.toggleAllSelections(isSelected);
    });

    this.subscribeToSelectFormChanges();
  }

  private subscribeToSelectFormChanges(): void {
    this.selectForm.valueChanges.subscribe((value) => {
      if (Object.values(value).includes(false)) {
        this.selectForm.controls.selectAll.setValue(false, { emitEvent: false });
      }
    });
  }

  private selectedItemIndex(itemId: number) {
    return this.selectedItems.findIndex((item) => item.id === itemId);
  }

  private currentlySelected() {
    return this.selectedItems.map((item) => item.id);
  }

  private setPaginationOptions(paginationData: { current_page: number; total: number }): void {
    this.paginationOptions.name = 'itemListPagination';
    this.paginationOptions.label = 'sale items';
    this.paginationOptions.currentPage = paginationData.current_page;
    this.paginationOptions.total = paginationData.total;
  }

  private sortSelected(): void {
    this.selectedItems.sort((a, b) => a.full_name.localeCompare(b.full_name));
  }

  // Build Query Params
  private get baseParams(): any {
    if(this.minItemPrice !== -1) {
      return {
        organization_id: this.organizationId,
        include_zero_rate: 1,
        min_price: this.minItemPrice,
      };
    }
    return {
      organization_id: this.organizationId,
      include_zero_rate: 1,
    };
  }

  private get paginationParams(): any {
    return {
      page: this.paginationOptions.currentPage || 1,
      per_page: this.paginationOptions.limit || 25,
    };
  }

  private get searchParams(): any {
    return {
      search_fields: 'full_name,sku',
      search_term: this.searchTerm,
    };
  }

  private get sortParams(): any {
    return {
      'order[full_name]': 'asc',
    };
  }

  public ngOnDestroy(): void {
    this.formSubscription?.unsubscribe();
    if (this.selectForm) {
      this.selectForm.reset();
    }
  }
}
