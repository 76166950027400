import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api-response.model';

@Injectable({
  providedIn: 'root',
})
export class SurveyResultsService {
  constructor(private httpClient: HttpClient) {}

  get(id: string): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(`${environment.apiUri}/survey_results/${id}`, this.getOptions());
  }

  protected getSalesUri(): string {
    return environment.apiUri + '/survey_results';
  }

  private getOptions(): object {
    return {
      withCredentials: true,
      headers: {
        Accept: 'application/vnd.ngin-api.v2, application/json',
      },
    };
  }
}
