import { Injectable } from '@angular/core';
import saveAs from 'file-saver';
import { Observable } from 'rxjs';
import { SeFeApiService } from 'se-fe-api';

@Injectable({
  providedIn: 'root',
})
export class CsvExportService {
  constructor(private api: SeFeApiService) {}

  public saveAsCSV(fileName: string, endpoint: string, apiParams?: any): void {
    this.exportData(endpoint, apiParams).subscribe((buffer) => {
      const data: Blob = new Blob([buffer], {
        type: 'text/csv',
      });
      saveAs(data, `${fileName}.csv`);
    });
  }

  public exportData(endpoint: string, params?: any): Observable<any> {
    const options = {
      responseType: 'text/csv',
      headers: {
        Accept: 'text/csv',
      },
    };
    return this.api.get(`${endpoint}?`.concat(this.parseQueryParams(params)), options);
  }

  public parseQueryParams(params: object): string {
    let pathBuilder = '';
    Object.keys(params).forEach((key) => {
      pathBuilder = pathBuilder.concat(`&${key}=${params[key]}`);
    });
    return pathBuilder.trim();
  }
}
