import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/api-response.model';
import { SaleItem } from '../models/sale-item.model';


@Injectable({
  providedIn: 'root'
})
export class SaleItemService {

  constructor(private httpClient: HttpClient) { }

  getSaleItems(params: object): Observable<ApiResponse<SaleItem[]>> {
    return this.httpClient.get<ApiResponse<SaleItem[]>>(
      this.getSalesUri(),
      this.getOptions(params)
    );
  }

  protected getSalesUri(): string {
    return environment.apiUri + '/sale_items';
  }

  private getOptions(params: object): object {
    return {
      params,
      withCredentials: true,
      headers: {
        Accept: 'application/json'
      }
    };
  }
}
