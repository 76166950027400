import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SeAnalyticsService } from 'se-analytics';
import { CURRENT_ORG_ID } from '../../providers/current-org-id.provider';
import { Discount } from '../models/discount.model';
import { DiscountService } from '../services/discount.service';

@Component({
  selector: 'app-edit-discount',
  templateUrl: './edit-discount.component.html',
  styleUrls: ['./edit-discount.component.scss'],
})
export class EditDiscountComponent implements OnInit {
  public discountId: number;
  public discount: Discount;
  public discountDataLoading = true;

  constructor(
    @Inject(CURRENT_ORG_ID) public currentOrgId: number,
    private activatedRoute: ActivatedRoute,
    public discountService: DiscountService,
    public translateService: TranslateService,
    public seAnalyticsService: SeAnalyticsService
  ) {
    this.discountId = this.activatedRoute.snapshot.params.discountId;
  }

  public async ngOnInit(): Promise<void> {
    await this.loadDiscountData();
    this.seAnalyticsService.trackEvent('pageView', {
      depth1: 'HQ',
      depth2: 'Discounts',
      depth3: 'EditDiscount',
    });
  }

  private async loadDiscountData(): Promise<void> {
    this.discount = await this.discountService.find(this.discountId).finally(() => {
      this.discountDataLoading = false;
    });
  }
}
