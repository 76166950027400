export const environment = {
  name: 'staging',
  apiUri: 'https://api.stage.ngin-staging.com',
  apiBaseUrl: 'https://api.stage.ngin-staging.com',
  defaultUrl: '/',
  financialAdminLibraryUrl: 'https://se-financial-admin.ui.sestage.us',
  seFeFrameRouterOrigin: [
    'https://app.stage.ngin-staging.com',
    'https://org.ui.stage.ngin-staging.com',
    'https://org.stage.ngin-staging.com',
  ],
  seFeFrameRouterRedirectUrl: 'https://org.stage.ngin-staging.com/org/:orgId/APP_PATH/*',
  OAuthClientId: '8935f229fb063ed13df58970daf9d3ac', // update this
  OAuthResponseType: 'token',
  production: false,
  snFrontendBaseUrl: 'https://app.stage.ngin-staging.com',
  staging: true,
  userServiceBaseUrl: 'https://user.stage.ngin-staging.com',
  launchDarklyClientId: '5f4fc4507c0c78095ceeb4ed',
  domainEnvironments: [
    {
      domain: 'se-store-ui.stage.ngin-staging.com',
      default: true,
      urls: { api: 'https://api.stage.ngin-staging.com/' },
    },
    {
      domain: 'se-store-ui.sestage.us',
      default: false,
      urls: { api: 'https://se-api.sestage.us/' },
    },
  ],
};
